import React from "react";

const DeleteIcon = () => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_180263_7482)">
        <path
          d="M3.96457 1.60589H3.85742C3.91635 1.60589 3.96457 1.55767 3.96457 1.49874V1.60589H8.03599V1.49874C8.03599 1.55767 8.08421 1.60589 8.14314 1.60589H8.03599V2.57017H9.00028V1.49874C9.00028 1.02598 8.61591 0.641602 8.14314 0.641602H3.85742C3.38465 0.641602 3.00028 1.02598 3.00028 1.49874V2.57017H3.96457V1.60589ZM10.7146 2.57017H1.28599C1.04894 2.57017 0.857422 2.76169 0.857422 2.99874V3.42732C0.857422 3.48624 0.905636 3.53446 0.964565 3.53446H1.77349L2.1043 10.5389C2.12573 10.9956 2.5034 11.3559 2.9601 11.3559H9.04046C9.49849 11.3559 9.87483 10.997 9.89626 10.5389L10.2271 3.53446H11.036C11.0949 3.53446 11.1431 3.48624 11.1431 3.42732V2.99874C11.1431 2.76169 10.9516 2.57017 10.7146 2.57017ZM8.93733 10.3916H3.06323L2.73912 3.53446H9.26144L8.93733 10.3916Z"
          fill="black"
          fillOpacity="0.7"
        />
      </g>
      <defs>
        <clipPath id="clip0_180263_7482">
          <rect width="12" height="12" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default DeleteIcon;
