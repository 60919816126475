import { Button, Layout, Space, Tooltip, theme } from "antd";
import { FileTextOutlined, FolderAddOutlined } from "@ant-design/icons";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../reducers/reduxStore";
import { handleSubTopicModal, handleViewPlanModal } from "../../reducers/ModalSlice";
import { useEffect, useState } from "react";
import { userDetails } from "../../services/ApiActions/subscriptionAct";
import { setProfileDetails } from "../../reducers/UserSlice";
import { profile } from "console";

type HeaderProps = {
    folderUrl?: string;
    hideHeader?: boolean;
    title?: React.ReactNode;
    topicId?: string;
    subTopic?: string;
};

type UserDetailType = {
    username: string,
    name: string,
    profile_pic: any,
    organisation: string,
    subscriptionType?: string
}

const Headers = ({
    hideHeader = false,
    topicId = "",
    title,
    subTopic,
}: HeaderProps) => {
    const {
        token: { colorBgContainer },
    } = theme.useToken();
    const [subscriptionVisibility, setSubscriptionVisibility] = useState(false);
    const [userDetail, setUserDetail] = useState<UserDetailType | any>({});
    const { subTopicCount, profile } = useSelector((state: RootState) => state.userProfile)


    const navigate = useNavigate();
    const { sub_topic } = useParams();
    const dispatch = useDispatch<AppDispatch>();

    //   useEffect(() => {
    //     userDetails()
    //       .then((res: any) => {
    //         if(res.status===200){
    //           setUserDetail((prev: any) => ({ ...res.data}));
    //           localStorage.setItem('user_detail', JSON.stringify({...res.data}))
    //           if(userDetail?.subscriptionType === 'FREE' || !(userDetail.hasOwnProperty('subscriptionType'))){
    //             if(sub_topic !== undefined){
    //               setSubscriptionVisibility(true)
    //             }
    //           }
    //           else{
    //             setSubscriptionVisibility(false)
    //           }
    //         }
    //       })
    //       .catch((err) => {
    //         console.log("error", err);
    //       });
    //   }, []);

    return (
        <Layout.Header
            style={{ background: colorBgContainer }}
            className="sticky-header"
        >
            {!hideHeader && (
                <>
                    <h2 className="text-capitalize fs-18">{title}</h2>
                    <Space wrap>
                        <Tooltip placement="bottom" title="Create new sub topic">
                            <Button
                                onClick={() => {
                                    //   subscriptionVisibility===true ? dispatch(handleViewPlanModal(true)) : dispatch(handleSubTopicModal(topicId))
                                    dispatch(subTopicCount >= profile.subtopic_count ? handleViewPlanModal(true) : handleSubTopicModal(topicId))
                                }}
                                type="text"
                                icon={<FolderAddOutlined className="fs-20" />}
                            />
                        </Tooltip>
                        <Tooltip placement="bottomRight" title="View sub topic list">
                            <Button
                                onClick={() => {
                                    let url = `/topic/${topicId}`;
                                    if (subTopic) {
                                        url = `/topic/${topicId}/${subTopic}`;
                                    }
                                    navigate(url);
                                }}
                                type="text"
                                icon={<FileTextOutlined className="fs-18" />}
                            />
                        </Tooltip>
                    </Space>
                </>
            )}
        </Layout.Header>
    );
};

export default Headers;
