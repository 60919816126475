import axios from "axios";
import Authorization from "../helper/Authorization";
import { redirect } from "react-router-dom";
import { router } from "../App";

export type ApiRequestType = "get" | "post" | "put" | "delete";

interface APIType {
    api: string;
    method: ApiRequestType;
    baseurl?: string;
    body?: any;
    auth?: boolean;
}

const axiosInstance = axios.create({});

const api = async ({
    api,
    method = "get",
    baseurl = "baseurl",
    auth = true,
    body,
}: APIType) => {
    return await new Promise((resolve, reject) => {
        if (auth) {
            axiosInstance.defaults.headers.Authorization = `Bearer ${Authorization.getAccessToken()}`;
        }
        axiosInstance[method](`${getApiBaseUrl(baseurl)}${api}`, body ? body : "")
            .then((res: any) => {
                resolve(res.data);
            })
            .catch((error: any) => {
                if (error.response.status && error.response.status === 401) {
                    Authorization.logout();
                    router.navigate('/login', { replace: true })
                } else if (error.response) {
                    reject(error.response.data);
                } else {
                    reject(error);
                }
            });
    });
};

const getApiBaseUrl = (type: string) => {
    switch (type) {
        case "baseurl":
            return `${process.env.REACT_APP_API_BASE_URL}`;
        default:
            return process.env.REACT_APP_API_BASE_URL;
    }
};

export default api;
