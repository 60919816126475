import { Layout, theme } from "antd";
import React from "react";
import SettingSidebar from "./SettingSidebar";

type childrenProps = {
  children: React.ReactNode;
};

const Common = ({ children }: childrenProps) => {
  const {
    token: { colorBgContainer },
  } = theme.useToken();

  return (
    <Layout>
      <SettingSidebar />
      <Layout style={{ background: colorBgContainer }}>{children}</Layout>
    </Layout>
  );
};

export default Common;
