import React, { useState } from 'react'
import SplitPane from 'split-pane-react/esm/SplitPane'

type SplitePanelType = {
    children: JSX.Element[]
}

type PanelTypes = {
    children: React.ReactNode,
    style?: React.CSSProperties,
    className?: string
}

const SplitePanel = ({ children }: SplitePanelType) => {
    const [sizes, setSizes] = useState<(number | string)[]>(["50%", "50%"]);

    return (
        <SplitPane
            style={{
                minHeight: "100vh",
                width: "calc(100% - 227px)",
                marginLeft: "auto",
            }}
            split="vertical"
            sizes={sizes}
            onChange={setSizes}
            sashRender={() => <div className="custom-sash" />}
        >
            {children}
        </SplitPane>
    )
}

const LeftPane = ({ children, style = {}, className = "" }: PanelTypes) => {
    return (
        <div style={{ height: "100%", overflowY: "auto", ...style }} className={className}>
            {children}
        </div>
    )
}

const RightPane = ({ children, style = {}, className = "" }: PanelTypes) => {
    return (
        <div className={`${className} right-panel`} style={{ background: "#fff", height: "100%", overflowY: "auto", ...style }} >
            {children}
        </div>
    )
}

SplitePanel.LeftPane = LeftPane;
SplitePanel.RightPane = RightPane;

export default SplitePanel