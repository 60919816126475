import React from "react";
import { Input, Space, Dropdown, MenuProps, Divider } from "antd";
import { AudioOutlined } from "@ant-design/icons";
const { Search } = Input;

const suffix = (
  <AudioOutlined
    style={{
      fontSize: 16,
      color: "#1677ff",
    }}
  />
);
// const items: MenuProps['ite'] = [];

const SearchTable = () => {
  return (
    <>
      <Divider style={{ margin: "10px" }} />
      <Space className="flex" style={{ justifyContent: "space-between" }}>
        <Search
          placeholder="input search text"
          //onSearch={onSearch}
          style={{ width: 200 }}
        />
        <Dropdown>
          <Space>Hover me</Space>
        </Dropdown>
      </Space>
      <Divider style={{ margin: "10px" }} />
    </>
  );
};

export default SearchTable;

// // menu={{ items }}
// <a onClick={(e) => e.preventDefault()}>
