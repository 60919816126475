import { StorageManager } from "@aws-amplify/ui-react-storage";
import { Button, Col, Form, Input, Modal, Row } from "antd";
import { useFormik } from "formik";
import { useEffect, useRef, useState } from "react";
import { object, string } from "yup";
import { InfoColor } from "../Colors";
import { uploadSubTopicFiles } from "../../services/ApiActions/filesAct";
import Toast from "../../services/ToastMessage";
import { useParams } from "react-router-dom";
import { PDFManager } from "./PDFManager";

export type UploadFileType = {
    path: any;
    name: any;
    s3_prefix?: any;
    web_url?: string;
    yt_url?: string;
};

type FileUploadModalProps = {
    openModal: boolean;
    subTopicName: string;
    closeModal: () => void;
    onSuccessUpload: (isFileProcessed: boolean) => void;
};

const validationSchema = object().shape({
    website_name: string().when("website_url", {
        is: (val: string) => val && val.trim() !== "",
        then: (schema) => schema.required("Please enter website name"),
    }),
    website_url: string().url("Please enter a valid url"),
    video_name: string().when("video_url", {
        is: (val: string) => val && val.trim() !== "",
        then: (schema) => schema.required("Please enter video name"),
    }),
    video_url: string().url("Please enter a valid url"),
});

const FileUploadModal = ({
    openModal,
    subTopicName,
    closeModal,
    onSuccessUpload,
}: FileUploadModalProps) => {
    const storageRef = useRef<any>(null);
    const { topic, sub_topic } = useParams();
    const [formError, setFormError] = useState("");
    const [files, setFiles] = useState<UploadFileType[]>([]);

    const { values, errors, resetForm, handleBlur, handleChange, handleSubmit } =
        useFormik({
            initialValues: {
                sub_topic: "",
                file: "",
                website_name: "",
                website_url: "",
                video_name: "",
                video_url: "",
            },
            validationSchema,
            onSubmit: (data) => {
                if (
                    data.video_url === "" &&
                    data.website_url === "" &&
                    files.length === 0
                ) {
                    setFormError(
                        "Please fill atleast one of them file or web link or youtube link"
                    );
                    return;
                }
                setFormError("");
                let body = files;

                if (data.website_url.trim() !== "") {
                    body = [
                        ...body,
                        {
                            path: data.website_url,
                            name: data.website_name,
                            web_url: data.website_url,
                        },
                    ];
                }

                if (data.video_url.trim() !== "") {
                    body = [
                        ...body,
                        {
                            path: data.video_url,
                            name: data.video_name,
                            yt_url: data.video_url,
                        },
                    ];
                }

                uploadSubTopicFiles(topic as string, sub_topic as string, body)
                    .then((res: any) => {
                        // if (res.status === "done") {
                        let fileProcessed = false;
                        if (res.data.length > 0 && res.data[0].status === "PROCESSED") {
                            fileProcessed = true;
                        }
                        onSuccessUpload(fileProcessed);
                        // }
                    })
                    .catch((error) => {
                        Toast({
                            type: "error",
                            message: error?.message || "Something went wrong",
                        });
                    });
            },
        });

    /**
     * On File uploaded successful on S3  bucket
     * @param { string } key
     */
    const onUploadSuccess = ({ key }: { key?: string }) => {
        // let name = key?.split(".").slice(0, -1).join(".");
        let name = key;
        let url = `${process.env.REACT_APP_S3_URL}/${key}`;

        setFiles((pre: any) => [
            ...pre,
            { name, path: url, s3_prefix: `public/${key}` },
        ]);
    };

    /**
     * On File uploaded successful on S3 bucket
     */
    const onUploadError = () => {
        Toast({
            message: "Something went wrong while uploading file",
            type: "error",
        });
        storageRef.current?.clearFiles();
    };

    const onFileRemove = ({ key }: { key?: string }) => {
        let _files = files.filter((item) => item.s3_prefix !== `public/${key}`);
        setFiles([..._files]);
    };

    useEffect(() => {
        resetForm();
        setFiles([]);
        setFormError("");
        storageRef.current?.clearFiles();
    }, [openModal, resetForm]);

    return (
        <Modal
            title="Upload files"
            centered
            open={openModal}
            footer={null}
            className="topic-modal"
            maskClosable={false}
            keyboard={false}
            closeIcon={
                <div onClick={closeModal} aria-label="close">
                    X
                </div>
            }
        >
            <Form layout="vertical" onSubmitCapture={handleSubmit}>
                <div className="modal-content">
                    <Form.Item label="Sub topic name">
                        <Input
                            size="large"
                            className="chat-bot-input"
                            value={subTopicName}
                            disabled
                        />
                    </Form.Item>

                    <PDFManager setFilesList={setFiles} filesList={files} ref={storageRef} />

                    {/* <Form.Item label="Upload PDF/Documents">
                            <StorageManager
                                acceptedFileTypes={[".pdf"]}
                                accessLevel="public"
                                maxFileCount={1}
                                // maxFileSize={5 * 1000 * 1001}
                                onUploadSuccess={onUploadSuccess}
                                onUploadError={onUploadError}
                                ref={storageRef}
                                onFileRemove={onFileRemove}
                            />
                        </Form.Item> */}

                    {formError !== "" && Object.keys(errors).length === 0 && (
                        <p className="form-error mt-10">Please choose file</p>
                    )}
                </div>
                <div className="ant-modal-footer">
                    <Button
                        type="default"
                        onClick={closeModal}
                        className="border-rounded-2"
                    >
                        Cancel
                    </Button>
                    <InfoColor>
                        <Button
                            type="primary"
                            htmlType="submit"
                            className="border-rounded-2"
                        >
                            Save
                        </Button>
                    </InfoColor>
                </div>
            </Form>
        </Modal>
    );
};

export default FileUploadModal;
