import thunk from "redux-thunk";
import modalReducer from "./ModalSlice";
import { applyMiddleware, configureStore } from "@reduxjs/toolkit";
import api from "../services/api";
import UserSlice from "./UserSlice";


const store = configureStore({
    reducer: {
        modal: modalReducer,
        userProfile: UserSlice
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            thunk: {
                extraArgument: api
            }
        })
})

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;