import { Button, Card } from 'antd'
import React, { useEffect, useState } from 'react'
import SuccessIcon from '../../icons/SuccessIcon'
import { useNavigate } from 'react-router'
import { replace } from 'formik'
import { useSearchParams } from 'react-router-dom'
import { getLastTransaction } from '../../services/ApiActions/subscriptionAct'
import Toast from '../../services/ToastMessage'
import Authorization from '../../helper/Authorization'

const PaymentSuccess = () => {
    const navigate = useNavigate();
    const [urlSearchParams] = useSearchParams();
    const [transaction, setTransaction] = useState<any>();

    useEffect(() => {
        if (!urlSearchParams.has('token')) {
            return navigate('/', { replace: true })
        }
        const token = urlSearchParams.get('token');
        Authorization.updateAccessToken(token as string);
        getLastTransaction().then((res: any) => {
            if (res.status === 200) {
                setTransaction(res.data);
                if (res.data.orderStatus !== true) {
                    navigate('/payment_failed', { replace: true });
                }
            }
        }).catch(() => {
            Toast({ type: "error", message: 'No transaction found' })
        })
    }, [urlSearchParams])

    return (
        <section className='payment-section'>
            <Card>
                <div className="card-title">
                    <SuccessIcon />
                    <h2 className='success'>Payment Successful</h2>
                    {/* <span>{(Object.keys(transaction).length > 0 && transaction.startDate !== undefined) && moment(transaction.startDate).format('MMMM Do YYYY, h:mm:ss a')}</span> */}
                    <span>Oct 6, 2023 4:51 PM</span>
                </div>
                <h3>Thank you! Your payment of ₹35.8801 has been received.</h3>
                <div className="data-list">
                    <div className="data-column">Subscription</div>
                    <div className="data-item">{transaction?.planType} ₹5.99/mo</div>
                    <div className="data-column">Order ID</div>
                    <div className="data-item">AIFISE0865</div>
                    <div className="data-column">Payment Mode</div>
                    <div className="data-item">Card</div>
                    <div className="data-column">Transaction ID</div>
                    <div className="data-item lh-1-2">{transaction?.transactionId}</div>
                </div>
                <div className="d-flex" style={{ columnGap: 16 }}>
                    <Button size='large' block style={{ borderRadius: 2 }} onClick={() => navigate('/', { replace: true })}>
                        Back
                    </Button>
                    <Button size='large' type='primary' block style={{ borderRadius: 2 }} onClick={() => navigate('/', { replace: true })}>
                        Chat Now
                    </Button>
                </div>
            </Card>
        </section>
    )
}

export default PaymentSuccess