import React from 'react'
import AuthLayout from '../../components/Layout/AuthLayout'
import Paragraph from 'antd/es/typography/Paragraph'
import { Button } from 'antd'

const EmailSuccess = () => {
    return (
        <AuthLayout>
            <h1>Check your mail</h1>
            <Paragraph className="mb-6">
                Please check your email. You'll find instructions on resetting your password at abc@gmail.com.
            </Paragraph>
            <Button
                size="large"
                block
                style={{ borderRadius: "2px", margin: "16px 0px" }}
                type="primary"
            >
                Resend email
            </Button>
            <Paragraph className='mt-6 mb-0'>
                If you haven't received an email within five minutes, please check your spam folder or consider using a different email address.
            </Paragraph>
        </AuthLayout>
    )
}

export default EmailSuccess