import React from 'react'

const SuccessIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="49" height="48" viewBox="0 0 49 48" fill="none">
            <path d="M24.5 3C12.9031 3 3.5 12.4031 3.5 24C3.5 35.5969 12.9031 45 24.5 45C36.0969 45 45.5 35.5969 45.5 24C45.5 12.4031 36.0969 3 24.5 3ZM33.5703 17.1422L23.6984 30.8297C23.5605 31.0223 23.3786 31.1792 23.1678 31.2874C22.9571 31.3957 22.7236 31.4522 22.4867 31.4522C22.2498 31.4522 22.0163 31.3957 21.8056 31.2874C21.5949 31.1792 21.413 31.0223 21.275 30.8297L15.4297 22.7297C15.2516 22.4812 15.4297 22.1344 15.7344 22.1344H17.9328C18.4109 22.1344 18.8656 22.3641 19.1469 22.7578L22.4844 27.3891L29.8531 17.1703C30.1344 16.7812 30.5844 16.5469 31.0672 16.5469H33.2656C33.5703 16.5469 33.7484 16.8938 33.5703 17.1422Z" fill="#52C41A" />
        </svg>
    )
}

export default SuccessIcon