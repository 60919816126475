import { ConfigProvider } from 'antd'

type InfoColorProps = {
    children: React.ReactNode
}

export const InfoColor = ({ children }: InfoColorProps) => {
    return (
        <ConfigProvider
            theme={{
                token: {
                    colorPrimary: "#1890FF"
                }
            }}
        >
            {children}
        </ConfigProvider>
    )
}