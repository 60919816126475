import React, { useEffect, useState } from "react";
import type { MenuProps } from "antd";
import {
    Button,
    Layout,
    Menu,
    Popover,
    theme,
    Modal,
    Input,
    Space,
    Select,
    Divider,
} from "antd";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { SiderHeader } from "./includes/SiderHeader";
import SiderFooter from "./includes/SiderFooter";
import { InfoColor } from "../Colors";
import { MenuListType } from ".";
import { EllipsisOutlined } from "@ant-design/icons";
import DeleteIcon from "../../icons/DeleteIcon";
import RenameIcon from "../../icons/RenameIcon";
import {
    deleteTopic,
    getTopicDetails,
} from "../../services/ApiActions/topicAct";
import AddMembersIcon from "../../icons/AddMembersIcon";
import { EditOutlined } from "@ant-design/icons";
import { forceReFetchMenulist } from "../../reducers/ModalSlice";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../reducers/reduxStore";
import { deleteSubtopic } from "../../services/ApiActions/subTopicAct";
import MenuItem from "antd/es/menu/MenuItem";
import { topicApi } from "../../services/apiVariables";
import Toast from "../../services/ToastMessage";
import { TopicDataType } from "../../pages/TopicChat";
import { MoveToPlanModal, SubTopicMenu, TopicMenu } from "./includes";
import UserProfileModal from "../Widget/UserProfileModal";
import { userDetails } from "../../services/ApiActions/subscriptionAct";
import { setCounts, setProfileDetails } from "../../reducers/UserSlice";

const { Sider } = Layout;

type MenuItem = Required<MenuProps>["items"][number];

type SideBarProps = {
    menuList: MenuListType[];
};

const options = [
    {
        value: "Topic Owner",
        label: "Topic Owner",
    },
    {
        value: "Topic member",
        label: "Topic member",
    },
];

const optionsSelect = [
    {
        value: "user 1",
        label: "user 1",
    },
    {
        value: "user 2",
        label: "user 2",
    },
];

const Sidebar = ({ menuList }: SideBarProps) => {
    const [menuItems, setMenuItems] = useState<MenuItem[]>([]);
    const [activeKey, setActiveKey] = useState<string[]>([]);
    const [isModalOpen, setIsModalOpen] = useState("");
    const [isModalOpenSub, setIsModalOpenSub] = useState("");
    const [openRenamePopover, setOpenRenamePopover] = useState(false);
    const [openAddMemberModal, setOpenAddMemberModal] = useState("");
    const [reFetch, setReFetch] = useState(false);
    const navigate = useNavigate();

    const location = useLocation();
    const {
        token: { colorBgContainer },
    } = theme.useToken();

    const { topic } = useParams();

    const dispatch = useDispatch<AppDispatch>();
    const { reFetchingMenu } = useSelector((state: RootState) => state.modal);
    const { profile } = useSelector((state: RootState) => state.userProfile);

    function getItem(
        id: string,
        label: React.ReactNode | string,
        key: React.Key,
        href: string = "/",
        children: MenuItem[] = [],
        isMainTopic: boolean = false
    ): MenuItem {
        let menuLabel = label;

        if (!isMainTopic) {
            menuLabel = (
                <SubTopicMenu
                    label={label}
                    href={href}
                    onClickDelete={() => setIsModalOpenSub(id)}
                />
            );
        } else {
            menuLabel = (
                <TopicMenu
                    haveChildren={children.length > 0}
                    href={href}
                    label={label}
                    onClickDelete={() => setIsModalOpen(id)}
                />
            );
        }
        return {
            key: key,
            children: children.length > 0 ? children : undefined,
            label: menuLabel,
        } as MenuItem;
    }

    const handleOk = () => {
        deleteTopic(isModalOpen)
            .then((res: any) => {
                if (res.data.status === 200) {
                    dispatch(forceReFetchMenulist(!reFetchingMenu));
                    Toast({ message: "Topic removed successfully" });
                    navigate("/", { replace: true });
                }
            })
            .catch((error) => {
                Toast({
                    type: "error",
                    message: error?.message || "Something went wrong",
                });
            });

        handleCancel();
    };

    const handleCancel = () => setIsModalOpen("");

    const handleOkSub = () => {
        deleteSubtopic(topic as string, isModalOpenSub)
            .then((res: any) => {
                if (res.data.status === 200) {
                    setReFetch(!reFetch);
                    dispatch(forceReFetchMenulist(!reFetchingMenu));
                    Toast({ message: "Sub topic removed successfully" });
                    navigate("/", { replace: true });
                }
            })
            .catch((error) => {
                Toast({
                    type: "error",
                    message: error?.message || "Something went wrong",
                });
            });

        handleCancelSub();
    };

    const handleCancelSub = () => setIsModalOpenSub("");

    useEffect(() => {
        let topicCount = 0;
        let subTopicCount = 0;
        const items: MenuItem[] = menuList?.map((item: MenuListType) => {
            topicCount++;
            return getItem(
                item.id,
                item.name,
                `/chat/${item.id}`,
                `/chat/${item.id}`,
                item.subtopics.map((item2) => {
                    subTopicCount++;
                    return getItem(
                        item2.id,
                        item2.name,
                        `/chat/${item.id}/${item2.id}`,
                        `/chat/${item.id}/${item2.id}`
                    )
                }),
                true
            );
        });

        dispatch(setCounts({ topicCount, subTopicCount }));
        setMenuItems(items);
    }, [menuList]);

    useEffect(() => {
        let locationUrl = location.pathname.split("/");
        if (locationUrl.length > 3 && locationUrl[1] === "topic") {
            let topicActiveurl = `/chat/${locationUrl[2]}`;
            setActiveKey([`${topicActiveurl}/${locationUrl[3]}`, topicActiveurl]);
        } else if (locationUrl.length > 3) {
            setActiveKey([location.pathname, `/${locationUrl[1]}/${locationUrl[2]}`]);
        } else {
            setActiveKey([location.pathname]);
        }
    }, [location]);

    const AddMemberComponent = () => {
        const index = menuList.findIndex((arr, i) => arr.id === openAddMemberModal);
        return <div>Invite people to {menuList[index].name}</div>;
    };

    useEffect(() => {
        if (isModalOpen) {
            const handleKeyDown = (event: KeyboardEvent) => {
                if (event.key === "Enter") {
                    handleOk();
                }
            };
            document.addEventListener("keydown", handleKeyDown);
            return () => {
                document.removeEventListener("keydown", handleKeyDown);
            };
        }
    }, [isModalOpen]);

    useEffect(() => {
        if (isModalOpenSub) {
            const handleKeyDown = (event: KeyboardEvent) => {
                console.log("handleKeyDown");
                if (event.key === "Enter") {
                    handleOkSub();
                }
            };
            document.addEventListener("keydown", handleKeyDown);
            return () => {
                document.removeEventListener("keydown", handleKeyDown);
            };
        }
    }, [isModalOpenSub]);

    useEffect(() => {
        if (profile.name === undefined) {
            userDetails()
                .then((res: any) => {
                    if (res.status === 200) {
                        const { data } = res;
                        dispatch(setProfileDetails(data))
                    }
                })
                .catch((err) => {
                    console.log("error", err);
                });
        }
    }, [])


    return (
        <Sider
            className="sidebar-menu"
            style={{
                background: colorBgContainer,
            }}
            breakpoint="md"
        >
            <SiderHeader />
            <div className="mb-10">
                <label style={{ color: "#8C8C8C"}}>Recent Topics </label>
            </div>
            {
                (menuItems.length > 0) ?
                    <InfoColor>
                        <Menu
                            style={{
                                // height: `calc(100vh - ${profile.subscriptionType==="PRO"? '220px': '275px'})`
                                height: `calc(100vh - 215px)`
                            }}
                            className="sidebar-menu-list"
                            selectedKeys={activeKey}
                            mode="inline"
                            items={menuItems}
                            // openKeys={activeKey}
                            defaultOpenKeys={activeKey}
                        />
                    </InfoColor>
                    :
                    <div 
                        style={{ 
                            height: `calc(100vh - 210px)`,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: "center",
                            color: "#8C8C8C",
                            fontSize: 14
                        }}>No topics</div>
            }

            <MoveToPlanModal />

            <Modal
                title="Delete Topic"
                className="topic-modal"
                open={isModalOpen !== ""}
                onOk={handleOk}
                onCancel={handleCancel}
                footer={[
                    <Button key="back" className="border-rounded-2" onClick={handleCancel}>
                        Cancel
                    </Button>,
                    <Button key="submit" className="border-rounded-2" type="primary" onClick={handleOk}>
                        Delete
                    </Button>,
                ]}
            >
                <p className="px-24">Are you sure you want to delete ?</p>,
            </Modal>

            <Modal
                    title="Delete Sub Topic"
                    className="topic-modal"
                open={isModalOpenSub !== ""}
                onOk={handleOkSub}
                onCancel={handleCancelSub}
                footer={[
                    <Button key="back" className="border-rounded-2" onClick={handleCancelSub}>
                        Cancel
                    </Button>,
                    <Button key="submit" className="border-rounded-2" type="primary" onClick={handleOkSub}>
                        Delete
                    </Button>,
                ]}
            >
                <p className="px-24">Are you sure you want to delete ?</p>,
            </Modal>

            <Modal
                title={<AddMemberComponent />}
                open={openAddMemberModal !== ""}
                onOk={() => setOpenAddMemberModal("")}
                onCancel={() => setOpenAddMemberModal("")}
                footer={[
                    <Button key="back" className="border-rounded-2" onClick={() => setOpenAddMemberModal("")}>
                        Cancel
                    </Button>,
                    <Button
                        key="submit"
                        type="primary"
                        className="border-rounded-2"
                        onClick={() => setOpenAddMemberModal("")}
                    >
                        Invite
                    </Button>,
                ]}
            >
                <Divider style={{ marginBottom: "35px" }} />
                <Space.Compact style={{ width: "100%" }}>
                    <Select
                        mode="multiple"
                        allowClear
                        style={{ width: "100%" }}
                        placeholder="Please select"
                        // onChange={''}
                        options={optionsSelect}
                    />
                    <Select defaultValue="Topic Owner" options={options} />
                </Space.Compact>
                <Divider style={{ marginTop: "35px" }} />
            </Modal>
            <SiderFooter name={profile?.name as string} subscriptionType={profile?.subscriptionType || "FREE"} />
        </Sider>
    );
};

export default Sidebar;
