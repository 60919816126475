import { Button, Modal, Popover, Space } from "antd";
import React, { useEffect, useState } from "react";
import DeleteIcon from "../../../icons/DeleteIcon";
import { EllipsisOutlined } from "@ant-design/icons";
import { Link, useNavigate } from "react-router-dom";
import { SubTopicMenuProps, TopicMenuProps } from "./MenuType";
import { useDispatch, useSelector } from "react-redux";
import { handleViewPlanModal } from "../../../reducers/ModalSlice";
import { AppDispatch, RootState } from "../../../reducers/reduxStore";

export const TopicMenu = ({
    label,
    onClickDelete,
    haveChildren,
    href,
}: TopicMenuProps) => {
    const [visibility, setVisibility] = useState(false);
    const [labelText, setLabelText] = useState<string>(label);

    const truncateText = () => {
        if (labelText.length > 8) return labelText.slice(0, 8) + "...";
        return labelText;
    };

    useEffect(() => {
        const text_label = truncateText();
        setLabelText(text_label);
    }, []);

    return (
        <div className="topic-menu" title={label}>
            {haveChildren ? (
                <label>{labelText}</label>
            ) : (
                <Link to={href}>{labelText}</Link>
            )}
            <Popover
                className="menu-popover"
                open={visibility}
                onOpenChange={(open) => setVisibility(open)}
                trigger="click"
                content={
                    <div
                        style={{
                            width: 200,
                        }}
                    >
                        <Button
                            type="text"
                            className="popover-btn"
                            icon={<DeleteIcon />}
                            onClick={(e) => {
                                onClickDelete();
                                setVisibility(false);
                                e.stopPropagation();
                            }}
                        >
                            Delete
                        </Button>
                        {/* <Button
                        className="popover-btn"
                        type="text"
                        icon={<RenameIcon />}
                        onClick={showRenamePopover}
                    >
                        Rename
                    </Button> */}
                        {/* <Button
                        type="text"
                        className="popover-btn"
                        icon={<AddMembersIcon />}
                        onClick={() => setOpenAddMemberModal(id)}
                    >
                        Add members
                    </Button> */}
                    </div>
                }
            >
                <Button
                    type="text"
                    onClick={(e) => e.stopPropagation()}
                    icon={<EllipsisOutlined />}
                />
            </Popover>
        </div>
    );
};

export const SubTopicMenu = ({
    label,
    href,
    onClickDelete,
    isMainTopic,
}: SubTopicMenuProps) => {
    const [labelText, setLabelText] = useState<string>(label);

    const truncatedText = () => {
        if (labelText.length > 8) return labelText.slice(0, 8) + "...";
        return labelText;
    };

    useEffect(() => {
        const label_Text = truncatedText();
        setLabelText(label_Text);
    }, []);

    return (
        <div className="subTopic-menu" title={label}>
            <Link to={href}>{labelText}</Link>
            <div className="subTopic-menu-popover" style={{ color: "#808080" }}>
                {/*
Rename Subtopics 
<EditOutlined /> */}

                <Button
                    type="text"
                    icon={<DeleteIcon />}
                    onClick={(e) => {
                        onClickDelete();
                        e.stopPropagation();
                    }}
                />
            </div>
        </div>
    );
};

export const MoveToPlanModal = () => {
    const dispatch = useDispatch<AppDispatch>();
    const { viewPlanModal } = useSelector((state: RootState) => state.modal);

    const navigate = useNavigate();

    const handleOk = () => {
        // navigate("/plans");
        dispatch(handleViewPlanModal(false));
        navigate('/contact')
    };

    const handleCancel = () => {
        dispatch(handleViewPlanModal(false));
    };

    return (
        <>
            <Modal
                className="topic-modal"
                open={viewPlanModal}
                title="Limit Reached!"
                onOk={handleOk}
                onCancel={handleCancel}
                footer={[
                    <Button
                        key="submit"
                        type="primary"
                        className="border-rounded-0"
                        // size="large"
                        onClick={handleOk}
                    >
                        {/* View Plans */}
                        Contact us
                    </Button>,
                ]}
            >
                <p className="px-24 text-justify">You have reached the limit of your free usage. To continue enjoying our services, please consider upgrading your plan.</p><br />
                <p className="px-24 text-justify">For assistance in upgrading your plan, kindly reach out to our team.</p>
            </Modal>
        </>
    );
};
