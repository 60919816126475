import React, { useEffect, useState } from 'react'
import AuthLayout from '../../components/Layout/AuthLayout'
import { Button, Form, Input, Spin } from 'antd';
import { useNavigate } from 'react-router';
import Paragraph from 'antd/es/typography/Paragraph';
import { useSearchParams } from 'react-router-dom';
import { changePassword } from '../../services/ApiActions/authAction';
import Toast from '../../services/ToastMessage';

type FieldType = {
    password?: string;
    confirm_password?: string;
};

const ChangePassword = () => {
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const [isLoading, setLoading] = useState(false)
    const [token, setToken] = useState("");
    const [urlSearchParams] = useSearchParams();

    useEffect(() => {
        if (!urlSearchParams.has('token') || urlSearchParams.get("token") === "") {
            navigate('/login', { replace: true })
        } else {
            setToken(urlSearchParams.get("token") as string);
        }
    }, [])

    const onResetPassword = (data: any) => {
        changePassword({ password: data.password, token: token }).then((res: any) => {
            if (res.status === 200) {
                Toast({ message: "password reset successfully" })
                navigate('/reset/success', { replace: true });
            }
        }).catch((error: any) => {
            Toast({ type: "error", message: error?.message || "Something went wrong" })
        })
    }

    return (
        <AuthLayout>
            <Spin spinning={isLoading}>
                <h1>Reset Your Password</h1>
                <Paragraph className="mb-6">
                    Please create a new password
                </Paragraph>
                <Form
                    style={{ paddingTop: "12px" }}
                    layout="vertical"
                    form={form}
                    onFinish={onResetPassword}
                >
                    <Form.Item<FieldType>
                        label="Password"
                        style={{ marginBottom: 12 }}
                        name="password"
                        rules={[
                            { required: true, message: "Please enter your password" },
                            { min: 8, message: "Password must be 8 character long"},
                        ]}
                    >
                        <Input.Password
                            size="large"
                            className="chat-bot-input"
                            placeholder="Enter New Password"
                        />
                    </Form.Item>

                    <Form.Item<FieldType>
                        label="Confirm Password"
                        name="confirm_password"
                        dependencies={['password']}
                        style={{ marginBottom: 12, marginTop: 16 }}
                        rules={[
                            { required: true, message: "Please enter your password" },
                            ({ getFieldValue }) => ({
                                validator(_, value) {
                                    if (!value || getFieldValue('password') === value) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject(new Error('The new password that you entered do not match!'));
                                },
                            }),
                        ]}
                    >
                        <Input.Password
                            size="large"
                            className="chat-bot-input"
                            placeholder="Confirm Your Passwod"
                        // onPressEnter={onFinish}
                        />
                    </Form.Item>
                    <Button
                        size="large"
                        block
                        style={{ borderRadius: "2px", marginTop: 12 }}
                        type="primary"
                        htmlType="submit"
                    >
                        Continue
                    </Button>
                </Form>
            </Spin>
        </AuthLayout>
    )
}

export default ChangePassword