import { Button, Input } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../reducers/reduxStore";
import { hanleAddTopicModal, handleViewPlanModal } from "../../../reducers/ModalSlice";
import { LeftOutlined } from "@ant-design/icons";
import { useNavigate, useParams } from "react-router-dom";
import Logo from "../../../icons/Logo";
import LogoSm from "../../../icons/LogoSm";
import { useEffect, useState } from "react";
import { userDetails } from "../../../services/ApiActions/subscriptionAct";
import Toast from "../../../services/ToastMessage";
const { Search } = Input;


const CommonMenu = () => {
    const [subscriptionVisibility, setSubscriptionVisibility] = useState(false);
    const dispatch = useDispatch<AppDispatch>();
    const { topic } = useParams();
    const { topicCount, profile } = useSelector((state: RootState) => state.userProfile)

    // useEffect(() => {
    //     userDetails()
    //       .then((res: any) => {
    //         if(res.status===200){
    //           if(res.data.subscriptionType === 'FREE' || !(res.data.hasOwnProperty('subscriptionType'))){
    //             // if(topic !== undefined){
    //               setSubscriptionVisibility(true)
    //             // }
    //           }
    //           else{
    //             setSubscriptionVisibility(false)
    //           }
    //         }
    //       })
    //       .catch((err) => {
    //         console.log("error", err);
    //       });
    //   }, []);


    return (
        <div>
            <div className="logo mb-16">
                {/* <Logo /> */}
                <img src="/images/logo.png" className="d-md-block d-none" alt="Gist" />
                <img
                    src="/images/logo-sm.png"
                    className="d-block d-md-none"
                    alt="Gist"
                />
            </div>

            <Button
                onClick={() => {
                    // commented below 2 lines for adding condition on for free user. if paid user enable then use this code
                    // subscriptionVisibility=== true? dispatch(handleViewPlanModal(true)) :
                    // dispatch(hanleAddTopicModal(true))

                    (topicCount >= profile.topic_count) ? dispatch(handleViewPlanModal(true)) : dispatch(hanleAddTopicModal(true));
                }}
                type="primary"
                size="large"
                block
                className="border-rounded-0 mb-16 d-none d-md-block"
            >
                New Topic
            </Button>
        </div>
    );
};

export const SiderHeader = () => {
    return (
        <div className="sidebar-header">
            <CommonMenu />
            {/* <Search
                placeholder="Search"
                allowClear
                className="border-rounded-0 mb-16 d-none d-md-block"
                // onSearch={onSearch}
                size="middle"
            /> */}
        </div>
    );
};

export const SiderHeaderSetting = () => {
    const navigate = useNavigate();

    return (
        <div className="sidebar-header">
            <CommonMenu />
            <Button
                block
                size="large"
                icon={<LeftOutlined />}
                onClick={() => navigate("/")}
            >
                Back to Chat
            </Button>
        </div>
    );
};
