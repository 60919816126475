import React from 'react'

const LogoSm = ({ className = "" }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="33" viewBox="0 0 36 36" fill="none" className={className}>
            <g clipPath="url(#clip0_181244_1530)">
                <path d="M34.7143 3.90235H25.5214C23.5487 3.90235 21.6201 4.46886 19.9607 5.53761L18 6.7952L16.0393 5.53761C14.3816 4.46907 12.4508 3.90129 10.4786 3.90235H1.28571C0.574554 3.90235 0 4.4769 0 5.18806V28.0095C0 28.7206 0.574554 29.2952 1.28571 29.2952H10.4786C12.4513 29.2952 14.3799 29.8617 16.0393 30.9305L17.8232 32.0796C17.8754 32.1117 17.9357 32.1318 17.996 32.1318C18.0562 32.1318 18.1165 32.1157 18.1688 32.0796L19.9527 30.9305C21.6161 29.8617 23.5487 29.2952 25.5214 29.2952H34.7143C35.4254 29.2952 36 28.7206 36 28.0095V5.18806C36 4.4769 35.4254 3.90235 34.7143 3.90235ZM10.4786 26.4023H2.89286V6.7952H10.4786C11.9009 6.7952 13.283 7.20101 14.4763 7.96842L16.4371 9.22601L16.7143 9.40681V27.9693C14.8018 26.9407 12.6643 26.4023 10.4786 26.4023ZM33.1071 26.4023H25.5214C23.3357 26.4023 21.1982 26.9407 19.2857 27.9693V9.40681L19.5629 9.22601L21.5237 7.96842C22.717 7.20101 24.0991 6.7952 25.5214 6.7952H33.1071V26.4023ZM13.3754 11.9381H5.91027C5.75357 11.9381 5.625 12.0747 5.625 12.2394V14.0474C5.625 14.2122 5.75357 14.3488 5.91027 14.3488H13.3714C13.5281 14.3488 13.6567 14.2122 13.6567 14.0474V12.2394C13.6607 12.0747 13.5321 11.9381 13.3754 11.9381ZM22.3393 12.2394V14.0474C22.3393 14.2122 22.4679 14.3488 22.6246 14.3488H30.0857C30.2424 14.3488 30.371 14.2122 30.371 14.0474V12.2394C30.371 12.0747 30.2424 11.9381 30.0857 11.9381H22.6246C22.4679 11.9381 22.3393 12.0747 22.3393 12.2394ZM13.3754 17.5631H5.91027C5.75357 17.5631 5.625 17.6997 5.625 17.8644V19.6724C5.625 19.8372 5.75357 19.9738 5.91027 19.9738H13.3714C13.5281 19.9738 13.6567 19.8372 13.6567 19.6724V17.8644C13.6607 17.6997 13.5321 17.5631 13.3754 17.5631ZM30.0897 17.5631H22.6246C22.4679 17.5631 22.3393 17.6997 22.3393 17.8644V19.6724C22.3393 19.8372 22.4679 19.9738 22.6246 19.9738H30.0857C30.2424 19.9738 30.371 19.8372 30.371 19.6724V17.8644C30.375 17.6997 30.2464 17.5631 30.0897 17.5631Z" fill="#0D93F0" />
            </g>
            <defs>
                <clipPath id="clip0_181244_1530">
                    <rect width="36" height="36" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default LogoSm