import { Card } from "antd";
import React from "react";
import Logo from "../../../icons/Logo";

type AuthLayoutProps = {
    children: React.ReactNode;
};

const AuthLayout = ({ children }: AuthLayoutProps) => {
    return (
        <div
            className="auth-layout"
            style={{ backgroundImage: "url(/images/auth-bg.png)" }}
        >
            <div
                style={{
                    display: "flex",
                    marginBottom: "16px",
                    justifyContent: "space-around",
                }}
            >
                <img src="/images/logo.png" alt="logo" />
                {/* <Logo /> */}
            </div>
            <Card className="auth-card">{children}</Card>

            {/* <div
                style={{
                    display: "flex",
                    position: "absolute",
                    flexDirection: "column",
                    alignItems: "center",
                    bottom: "0",
                }}
            >
                {" "}
                <span>©AIFISE</span> <span>Terms of use | Privacy Policy</span>
            </div> */}

            {/* </div> */}
        </div>
    );
};

export default AuthLayout;
