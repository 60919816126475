import React from "react";
import ReactDOM from "react-dom";
import { Bar } from "@ant-design/plots";
import Typography from "antd/es/typography/Typography";

const ChartBar = () => {
  const data = [
    {
      action: "1",
      pv: 50000,
    },
    {
      action: "2",
      pv: 35000,
    },
    {
      action: "3",
      pv: 25000,
    },
    {
      action: "4",
      pv: 15000,
    },
    {
      action: "5",
      pv: 8500,
    },
  ];

  const config = {
    data,
    xField: "pv",
    yField: "action",
    conversionTag: {},
    color: " #9579E2",
    barHightRatio: 0.8,
  };

  return (
    <div>
      <Typography className="font-14" style={{ marginBottom: "20px" }}>
        Popular Queries
      </Typography>
      <Bar {...config} />
    </div>
  );
};


export default ChartBar;
