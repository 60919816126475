import React from 'react'
import Headers from './Headers'
import { Layout, theme } from 'antd'

type ContentSectionProps = {
    children: React.ReactNode
    topic?: string
    headerTitle: React.ReactNode,
    subTopic?: string
}

const ContentSection = ({ children, topic, headerTitle, subTopic }: ContentSectionProps) => {
    const {
        token: { colorBgContainer },
    } = theme.useToken();

    return (
        <>
            <Headers
                title={headerTitle}
                topicId={topic}
                subTopic={subTopic}
            />
            <Layout.Content className='layout-content'>
                <div className="main-content" style={{ background: colorBgContainer }}>
                    {children}
                </div>
            </Layout.Content>
        </>
    )
}

export default ContentSection