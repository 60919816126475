import {
    Card,
    VisuallyHidden,
    Button,
    Flex,
    Text,
    Divider,
    Image,
    Loader,
    Icon,
} from "@aws-amplify/ui-react";
import { StorageManager } from "@aws-amplify/ui-react-storage";
import { forwardRef, useEffect } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../reducers/reduxStore";

export const PDFManager = forwardRef(({ setFilesList, filesList }: any, ref:any) => {

    return (
        <StorageManager
            acceptedFileTypes={[".pdf"]}
            accessLevel="public"
            maxFileCount={1}
            ref={ref}
            components={{
                Container({ children }) {
                    return <Card variation="elevated">{children}</Card>;
                },
                DropZone({ children, displayText, inDropZone, ...rest }) {
                    return (
                        <>
                            <Flex
                                alignItems="center"
                                direction="column"
                                padding="medium"
                                backgroundColor={inDropZone ? "brand.primary.10" : ""}
                                {...rest}
                            >
                                <Text>Drop files here</Text>
                                <Divider size="small" label="or" maxWidth="10rem" />
                                {children}
                            </Flex>
                        </>
                    );
                },
                FilePicker({ onClick }) {
                    return (
                        <>
                            {filesList?.length >= 1 ? (
                                ""
                            ) : (
                                <Button variation="primary" onClick={onClick}>
                                    Browse Files
                                </Button>
                            )}
                        </>
                    );
                },

                FileList({ files, onCancelUpload, onDeleteUpload }) {
                    const name = files[0]?.id;
                    let url = `${process.env.REACT_APP_S3_URL}/${name}`;

                    if (filesList?.length === 0 && files[0]?.key != undefined) {
                        setFilesList((pre: any) => [
                            ...pre,
                            { name, path: url, s3_prefix: `public/${name}` },
                        ]);
                    }

                    return (
                        <Flex direction="row">
                            {files.map(({ file, key, progress, id, status, uploadTask }) => (
                                <Flex
                                    key={key}
                                    justifyContent="center"
                                    alignItems="center"
                                    width="5rem"
                                    height="5rem"
                                    position="relative"
                                >
                                    {/* <Image
                    borderRadius="small"
                    height="100%"
                    objectFit="cover"
                    src={URL.createObjectURL(files)}
                    alt={key}
                  /> */}
                                    {progress < 100 ? (
                                        <>
                                            <Loader
                                                style={{ width: 95 }}
                                                textAlign={"left"}
                                                position="absolute"
                                                size="large"
                                                percentage={progress}
                                            isDeterminate
                                            />
                                        </>
                                    ) :
                                        <Flex justifyContent="space-between" alignItems="center">
                                        <p className="file-name">{filesList[0]?.name}</p>
                                            <Button
                                                style={{ border: "1px solid #a0a0a0", width: 48 }}
                                                opacity="100"
                                                borderRadius="xxl"
                                                backgroundColor="#fff"
                                                // backgroundColor="background.primary"
                                                // position="absolute"
                                                variation="link"
                                                size="large"
                                                onClick={() => {
                                                    if (status === "uploading") {
                                                        setFilesList([]);
                                                        // onCancelUpload({
                                                        //   id,
                                                        //   uploadTask
                                                        // });
                                                    } else {
                                                        onDeleteUpload({ id });
                                                        setFilesList([]);
                                                    }
                                                }}
                                            >
                                                <Icon
                                                    fontSize="large"
                                                    color="font.error"
                                                    viewBox={{ width: 512, height: 512 }}
                                                    paths={[
                                                        {
                                                            d: "M448 256c0-106-86-192-192-192S64 150 64 256s86 192 192 192 192-86 192-192z",
                                                            strokeWidth: "32",
                                                            fill: "none",
                                                            strokeMiterlimit: "10",
                                                            stroke: "currentColor",
                                                        },
                                                        {
                                                            d: "M320 320L192 192m0 128l128-128",
                                                            strokeWidth: "32",
                                                            fill: "none",
                                                            strokeLinecap: "round",
                                                            stroke: "currentColor",
                                                        },
                                                    ]}
                                                />
                                            </Button>
                                        </Flex>
                                    }
                                </Flex>
                            ))}
                        </Flex>
                    );
                },
            }}
        />
    );
});
