export const addQuery = (dataObject: any, apiObject: any) => {
    if (!dataObject) {
        return "";
    }

    const keys = [
        "limit",
        "after",
        "topic",
        "sub_topic"
    ]

    keys.map((key) => {
        if (dataObject.hasOwnProperty(key) && apiObject.query.hasOwnProperty(key)) {
            apiObject.addQuery = { key, payload: dataObject[key] }
        }
        return true;
    })
}

export const generateQuery = (query: any) => {
    let url = "";

    return (
        url +
        Object.keys(query).reduce((accumulator, key, index) => {
            if (
                query[key] === "" ||
                query[key] === null ||
                (query[key] !== null && query[key].toString().trim() === "")
            ) {
                return accumulator;
            } else {
                return accumulator + `${index !== 0 ? "&" : "?"}${key}=${query[key]}`;
            }
        }, "")
    )
}