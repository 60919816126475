import api from "../api";
import { topicApi } from "../apiVariables";

export const getTopicList = () => {
  return new Promise((resolve, reject) => {
    api({ ...topicApi.getTopiclist })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const createTopic = (body: any) => {
  return new Promise((resolve, reject) => {
    api({ ...topicApi.createTopic, body })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getTopicDetails = (topic: string) => {
  let { getTopic } = topicApi;
  getTopic.topicId = topic;
  return new Promise((resolve, reject) => {
    api({ ...topicApi.getTopic })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const deleteTopic = (topicId: string) => {
  let { deleteTopic } = topicApi;
  deleteTopic.topicId = topicId;

  return new Promise((resolve, reject) => {
    api({ ...deleteTopic })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
