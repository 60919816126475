import api from "../api"
import { authApi } from "../apiVariables"

export const login = (body: any) => {
    return new Promise((resolve, reject) => {
        api({ ...authApi.login, body }).then((res) => {
            resolve(res)
        }).catch((error) => {
            reject(error)
        })
    })
}

export const signupApi = (body: any) => {
    return new Promise((resolve, reject) => {
        api({ ...authApi.signup, body }).then((res) => {
            resolve(res)
        }).catch((error) => {
            reject(error)
        })
    })
}

export const verifyEmailApi = (body: any) => {
    return new Promise((resolve, reject) => {
        api({ ...authApi.verifyEmail, body }).then((res) => {
            resolve(res)
        }).catch((error) => {
            reject(error)
        })
    })
}

export const forgetPassword = (body: any) => {
    return new Promise((resolve, reject) => {
        api({ ...authApi.forgetPassword, body }).then((res) => {
            resolve(res)
        }).catch((error) => {
            reject(error)
        })
    })
}

export const changePassword = (body: any) => {
    return new Promise((resolve, reject) => {
        api({ ...authApi.changePassword, body }).then((res) => {
            resolve(res)
        }).catch((error) => {
            reject(error)
        })
    })
}