import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export type ChatModalStateType = {
    openTopicModal: boolean,
    openSubTopicModal: string,
    openFileModal: boolean,
    reFetchingMenu: boolean,
    isApiLoading: boolean
    viewPlanModal:boolean
}

const initialState: ChatModalStateType = {
    openTopicModal: false,
    openSubTopicModal: "",
    openFileModal: false,
    reFetchingMenu: false,
    isApiLoading: false,
    viewPlanModal: false
}

export const modalSlice = createSlice({
    name: 'modals',
    initialState,
    reducers: {
        hanleAddTopicModal: (state, action: PayloadAction<boolean>) => {
            state.openTopicModal = action.payload
        },
        handleSubTopicModal: (state, action: PayloadAction<string>) => {
            state.openSubTopicModal = action.payload;
        },
        handelFileModal: (state, action: PayloadAction<boolean>) => {
            state.openFileModal = action.payload
        },
        forceReFetchMenulist: (state, action: PayloadAction<boolean>) => {
            state.reFetchingMenu = action.payload
        },
        setApiLoading: (state, action: PayloadAction<boolean>) => {
            state.isApiLoading = action.payload;
        },
        handleViewPlanModal: (state, action: PayloadAction<boolean>) => {
            state.viewPlanModal = action.payload;
        }
    }
});

export const {
    hanleAddTopicModal,
    handleSubTopicModal,
    handelFileModal,
    forceReFetchMenulist,
    setApiLoading,
    handleViewPlanModal
} = modalSlice.actions;

export default modalSlice.reducer;