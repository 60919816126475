import { Navigate, RouterProvider, createBrowserRouter, } from "react-router-dom";
import { ConfigProvider, Layout } from "antd";
import Common from "./components/Layout/includes/setting/CommonLayout";
import Homepage from "./pages/Homepage";
import TopicChat from "./pages/TopicChat";
// import SubTopicChat from "./pages/SubTopicDataType";
import SubTopicChat from "./pages/SubTopicChat";
import { Amplify } from "aws-amplify";
import "@aws-amplify/ui-react/styles.css";
import SubTopicList from "./pages/SubTopicList";
import SubTopicFilesList from "./pages/SubTopicFilesList";
import Login from "./pages/Login";
import Auth from "./helper/Authorization";
import Analisys from "./pages/Overview";
import CommonLayout from "./components/Layout";
import "split-pane-react/esm/themes/default.css";
import { GoogleOAuthProvider } from "@react-oauth/google";
import Signup from "./pages/Signup";
import ForgetPassword from "./pages/ForgetPassword";
import EmailSuccess from "./pages/ForgetPassword/EmailSuccess";
import ChangePassword from "./pages/ForgetPassword/ChangePassword";
import SuccessPage from "./pages/ForgetPassword/SuccessPage";
import EmailSent from "./pages/ForgetPassword/EmailSent";
import SubscriptionPlans from "./pages/SubscriptionPlans";
import PaymentSuccess from "./pages/Payment/PaymentSuccess";
import PaymentFailed from "./pages/Payment/PaymentFailed";
import Contact from "./pages/Contact";

Amplify.configure({
    Auth: {
        identityPoolId: "ap-south-1:e4ec2cc8-e76f-409a-97e6-bf15b63d7b28", //REQUIRED - Amazon Cognito Identity Pool ID
        region: "ap-south-1", // REQUIRED - Amazon Cognito Region
    },
    Storage: {
        AWSS3: {
            bucket: process.env.REACT_APP_S3_BUCKET_NAME, //REQUIRED -  Amazon S3 bucket name
            region: "ap-south-1", //OPTIONAL -  Amazon service region
        },
    },
});

type AuthRouteProps = {
    component: React.ComponentType;
};

const AuthRoute = ({ component }: AuthRouteProps) => {
    if (!Auth.isLoggedIn()) {
        return <Navigate to="/login" />;
    }
    let Component = component;
    return <Component />;
};

export const router = createBrowserRouter([
    { path: "/login", element: <Login /> },
    { path: "/signup", element: <Signup /> },
    { path: "/email-sent", element: <EmailSent /> },
    { path: "/forget-password", element: <ForgetPassword /> },
    { path: "/email-success", element: <EmailSuccess /> },
    { path: "/change-password", element: <ChangePassword /> },
    { path: "/:type/success", element: <SuccessPage /> },
    { path: "/plans/:plan_id?", element: <SubscriptionPlans /> },
    { path: "/payment_success", element: <PaymentSuccess /> },
    { path: "/payment_failed", element: <PaymentFailed /> },
    { path: "/contact", element: <Contact /> },
    {
        path: "/",
        element: <CommonLayout />,
        children: [
            { path: "/", element: <AuthRoute component={Homepage} /> },
            {
                path: "/chat/:topic",
                element: <AuthRoute component={TopicChat} />,
            },
            {
                path: "/chat/:topic/:sub_topic",
                element: <AuthRoute component={SubTopicChat} />,
            },
            {
                path: "/topic/:topic/:sub_topic?",
                element: <AuthRoute component={SubTopicList} />,
            },
            {
                path: "/topic/:topic/:sub_topic/files",
                element: <AuthRoute component={SubTopicFilesList} />,
            },
        ],
    },
    {
        path: "/overview",
        element: (
            <Common>
                <Analisys />
            </Common>
        ),
    },
]);

function App() {
    return (
        <ConfigProvider
            theme={{
                token: {
                    colorPrimary: "#9579E2",
                    colorInfo: "#1890FF",
                },
            }}
        >
            <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENTID as string}>
                <RouterProvider router={router} />
            </GoogleOAuthProvider>
        </ConfigProvider>
    );
}

export default App;
