import { ApiRequestType } from "./api";
import { generateQuery } from "./api.helper";

export const topicApi = {
    getTopiclist: {
        url: "/topic",
        method: "get" as ApiRequestType,
        query: {
            limit: "",
            after: "",
        },
        get api() {
            return this.url + generateQuery(this.query);
        },
        set addQuery({ key, payload }: any) {
            this.query[key as keyof typeof this.query] = payload;
        },
    },
    createTopic: {
        api: "/topic/create",
        method: "post" as ApiRequestType,
    },
    getTopic: {
        url: "/topic/",
        method: "get" as ApiRequestType,
        topicId: "",
        get api() {
            return this.url + this.topicId;
        },
    },
    deleteTopic: {
        url: "/topic/",
        method: "delete" as ApiRequestType,
        topicId: "",
        get api() {
            return this.url + this.topicId;
        },
    },
};

export const subTopicApi = {
    getSubTopicList: {
        url: "/sub-topics",
        method: "get" as ApiRequestType,
        topic: "",
        get api() {
            return this.url + "/" + this.topic;
        },
    },
    createSubTopic: {
        url: "/create",
        method: "post" as ApiRequestType,
        topic: "",
        get api() {
            return "/sub-topics/" + this.topic + this.url;
        },
    },
    getSubTopic: {
        url: "/sub-topics/",
        method: "get" as ApiRequestType,
        topicId: "",
        subTopicId: "",
        get api() {
            return this.url + this.topicId + "/" + this.subTopicId;
        },
    },
    sendQuery: {
        api: "/chat/query",
        method: "post" as ApiRequestType,
        baseurl: "queryApi",
    },
    deleteSubtopic: {
        url: "/",
        urlSuffix: "sub-topics/",
        method: "delete" as ApiRequestType,
        topic: "",
        subTopic: "",
        get api() {
            return this.url + this.urlSuffix + this.topic + '/' + this.subTopic;
        },
    },

    getMessageHistory: {
        url: "/history/fetch",
        method: "get" as ApiRequestType,
        baseurl: "queryApi",
        query: {
            topic: "",
            sub_topic: "",
        },
        get api() {
            return this.url + generateQuery(this.query);
        },
        set addQuery({ key, payload }: { key: any; payload: string }) {
            this.query[key as keyof typeof this.query] = payload;
        },
    },
};

export const filesApi = {
    getSubTopicFiles: {
        url: "/",
        urlSuffix: "sub-topics/",
        method: "get" as ApiRequestType,
        topic: "",
        subTopic: "",
        get api() {
            return this.url + this.urlSuffix + this.topic + '/' + this.subTopic + "/files";
        },
    },
    uploadSubTopicFiles: {
        url: "/",
        urlSuffix: "sub-topics/",
        method: "post" as ApiRequestType,
        topic: "",
        subTopic: "",
        get api() {
            return (
                this.url + this.urlSuffix + this.topic + '/' + this.subTopic + "/upload-files"
            );
        },
    },
    deleteSubtopicFile: {
        url: "/",
        urlSuffix: "sub-topics/",
        method: "delete" as ApiRequestType,
        topic: "",
        subTopic: "",
        fileid: "",
        get api() {
            return (
                this.url +
                this.urlSuffix +
                this.topic + '/' +
                this.subTopic +
                "/files/" +
                this.fileid
            );
        },
    },
};

export const authApi = {
    login: {
        api: "/auth/login",
        method: "post" as ApiRequestType,
        baseurl: "auth",
        auth: false,
    },
    signup: {
        api: "/auth/signup",
        method: "post" as ApiRequestType,
        baseurl: "auth",
        auth: false,
    },
    verifyEmail: {
        api: "/auth/verify-email",
        method: "post" as ApiRequestType,
        baseurl: "auth",
        auth: false,
    },
    forgetPassword: {
        api: "/auth/forgetPassword-email",
        method: "post" as ApiRequestType,
        baseurl: "auth",
        auth: false,
    },
    changePassword: {
        api: "/auth/updatePassword",
        method: "post" as ApiRequestType,
        baseurl: "auth",
        auth: false,
    }
};

export const subscriptionApi = {
    initiatePayment: {
        api: '/payment/intiate',
        method: 'post' as ApiRequestType,
    },
    user_details: {
        api:'/users/details',
        method:'get' as ApiRequestType,
    },
    updateUserProfile: {
        api:'/users/set-details',
        method:'post' as ApiRequestType,
    },
    getPlans: {
        api:'/plans',
        method: 'get' as ApiRequestType,
    },
    planDetail: {
        url:'/plans/',
        plan_id: "",
        method: 'get' as ApiRequestType,
        get api(){
            return this.url + this.plan_id;
        }
    },
    lastTransaction: {
        api: '/payment/transactions',
        method: 'get' as ApiRequestType,
    }
}
