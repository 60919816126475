const Loader = () => {
  return (
    <div
      className="loader-container"
      style={{margin: "50px auto 20px auto" }}
    >
      <div className="loader">
        <div className="bubble"></div>
        <div className="bubble"></div>
        <div className="bubble"></div>
      </div>
    </div>
  );
};

export default Loader;
