import api from "../api";
import { filesApi } from "../apiVariables";

export const getSubTopicFiles = (topic: string, subTopic: string) => {
  let { getSubTopicFiles } = filesApi;
  getSubTopicFiles.topic = topic;
  getSubTopicFiles.subTopic = subTopic;
  return new Promise((resolve, reject) => {
    api({ ...filesApi.getSubTopicFiles })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const uploadSubTopicFiles = (topic: string, subTopic: string, body: any) => {
  let { uploadSubTopicFiles } = filesApi;
  uploadSubTopicFiles.topic = topic;
  uploadSubTopicFiles.subTopic = subTopic;
  return new Promise((resolve, reject) => {
    api({
      ...filesApi.uploadSubTopicFiles,
      body: { data: body, s3_bucket: process.env.REACT_APP_S3_BUCKET_NAME },
    })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const deleteSubtopicFile = (
  topic: string,
  subTopic: string,
  fileid: string
) => {
  let { deleteSubtopicFile } = filesApi;
  deleteSubtopicFile.topic = topic;
  deleteSubtopicFile.subTopic = subTopic;
  deleteSubtopicFile.fileid = fileid;
  return new Promise((resolve, reject) => {
    api({ ...filesApi.deleteSubtopicFile })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
