import { Button, Card } from 'antd'
import FailedIcon from '../../icons/FailedIcon'
import { useNavigate } from 'react-router'
import { getLastTransaction } from '../../services/ApiActions/subscriptionAct';
import Toast from '../../services/ToastMessage';
import { useEffect, useState } from 'react';

const PaymentFailed = () => {
    const navigate = useNavigate();
    const [transaction, setTransaction] = useState<any>();

    useEffect(() => {
        getLastTransaction().then((res: any) => {
            if (res.status === 200) {
                setTransaction(res.data);
                if(res.data.orderStatus===true){
                    navigate('/payment_success', { replace: true });
                }
            }
        }).catch(() => {
            Toast({ type: "error", message: 'No transaction found' })
        })
    }, [])
    return (
        <section className='payment-section'>
            <Card>
                <div className="card-title">
                    <FailedIcon />
                    <h2 className='failed'>Payment Failed</h2>
                    <span>Oct 6, 2023 4:51 PM</span>
                </div>
                <h3>Please try again! Your transaction of ₹35.8801 has been failed.</h3>
                <div className="data-list">
                    <div className="data-column">Subscription</div>
                    <div className="data-item">{transaction?.planType} ₹5.99/mo</div>
                    <div className="data-column">Order ID</div>
                    <div className="data-item">AIFISE0865</div>
                    <div className="data-column">Payment Mode</div>
                    <div className="data-item">Card</div>
                    <div className="data-column">Transaction ID</div>
                    <div className="data-item lh-1-2">{transaction?.transactionId}</div>
                </div>
                <div className="d-flex" style={{ columnGap: 16 }}>
                    <Button size='large' block style={{ borderRadius: 2 }} onClick={() => navigate('/', { replace: true })}>
                        Back
                    </Button>
                    <Button size='large' type='primary' block style={{ borderRadius: 2 }} onClick={() => navigate('/plans', { replace: true })}>
                        Retry
                    </Button>
                </div>
            </Card>
        </section>
    )
}

export default PaymentFailed