import { Button, Checkbox, Form, Input, Space, Spin } from "antd";
import Paragraph from "antd/es/typography/Paragraph";
import { Link, useNavigate } from "react-router-dom";
import AuthLayout from "../components/Layout/AuthLayout";
import { login } from "../services/ApiActions/authAction";
import Auth from "../helper/Authorization";
import Toast from "../services/ToastMessage";
import { GoogleLogin, useGoogleLogin } from '@react-oauth/google'
import { useState } from "react";
import { EMAIL_REGEX } from "../constant";
import { useEffect } from 'react'

type FieldType = {
    email?: string;
    password?: string;
};

const Login = () => {
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const [isLoading, setLoading] = useState(false)

    /**
     * calling api when form submitting
     * @param data { username: string, password: string}
     */

    const onFinish = (data: any) => {
        setLoading(true)
        login(data)
            .then((res: any) => {
                if (res.status === 200) {
                    Auth.login({ token: res.token, username: res.username });
                    let webSource = localStorage.getItem('websiteSource') !== null ? JSON.parse(localStorage.getItem('websiteSource') as string) : null;
                    if (webSource !== null && webSource.planType == 2) {
                        localStorage.removeItem('websiteSource');
                        navigate("/plans", { replace: true });
                    } else {
                        navigate("/", { replace: true });
                    }
                    Toast({ message: "Login successfully" });
                } else {
                    Toast({ message: res?.message, type: "error" });
                }
                setLoading(false)
            })
            .catch((error) => {
                setLoading(false)
                Toast({
                    message: error?.message || "Something went wrong",
                    type: "error",
                });
            });
    };

    return (
        <AuthLayout>
            <Spin spinning={isLoading}>
                <h1>Welcome</h1>
                <Paragraph className="mb-6">
                    Please enter your login information to access your Gist account
                </Paragraph>
                <Form
                    style={{ paddingTop: "12px" }}
                    layout="vertical"
                    form={form}
                    onFinish={(data: any) => onFinish({ ...data, provider: 'app' })}
                >
                    <Form.Item<FieldType>
                        label="Email"
                        style={{ marginBottom: 12 }}
                        name="username"
                        rules={[
                            { required: true, message: "Please enter your email" },
                            { pattern: EMAIL_REGEX, message: "Please enter a valid email" }
                        ]}
                    >
                        <Input
                            size="large"
                            className="chat-bot-input"
                            placeholder="Email Address"
                        />
                    </Form.Item>

                    <Form.Item<FieldType>
                        label="Password"
                        name="password"
                        style={{ marginBottom: 12, marginTop: 16 }}
                        rules={[{ required: true, message: "Please enter your password" }]}
                    >
                        <Input.Password
                            size="large"
                            className="chat-bot-input"
                            placeholder="Enter Your Passwod"
                        // onPressEnter={onFinish}
                        />
                    </Form.Item>
                    <div className="d-flex justify-content-end">
                        {/* <Checkbox>Remember Me</Checkbox> */}
                        <Link style={{ fontStyle: "italic", color: "#9579E2" }} to='/forget-password'>Forgot your Password?</Link>
                    </div>
                    <Button
                        size="large"
                        block
                        style={{ borderRadius: "2px", marginTop: 12 }}
                        type="primary"
                        htmlType="submit"
                    >
                        Login
                    </Button>
                </Form>
                <p className="my-16 text-center">
                    OR
                </p>
                <GoogleLogin
                    logo_alignment="center"
                    width="350px"
                    onSuccess={(res) => {
                        onFinish({ token: res.credential, provider: 'google' })
                    }}
                    onError={() => {
                        Toast({ type: "error", message: "Something went wrong, please try again leter" })
                    }}
                />
                <div className='auth-return-link'>
                    Don't have an account? <Link to="/signup">Sign Up</Link>
                </div>
            </Spin>
        </AuthLayout>
    );
};

export default Login;
