import { Table } from "antd";
import type { ColumnsType } from "antd/es/table";

const columns: ColumnsType<any> = [
  {
    title: "S.NO.",
    dataIndex: "no",
  },
  {
    title: "Documents",
    dataIndex: "documents",
  },
  {
    title: "Type",
    dataIndex: "type",
  },
  {
    title: "No of Pages",
    dataIndex: "page",
  },
  {
    title: "File Size",
    dataIndex: "filesize",
  },
  {
    title: "Uploded On",
    dataIndex: "uplode",
  },
];

const data = [
  {
    key: "1",
    no: "1",
    documents: "Abc",
    type: "pdf",
    page: "30",
    filesize: "20",
    uplode: "20-3-2",
  },
  // Add more data rows here...
];

function MyTable() {
  return <Table columns={columns} dataSource={data} />;
}

export default MyTable;
