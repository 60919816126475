import { Button, Card, Col, Layout, Row, Spin } from "antd";
import React, { useEffect, useState } from "react";
import Logo from "../icons/Logo";
import { InfoColor } from "../components/Colors";
import { useNavigate, useParams } from "react-router";
import {
    getPlanDetails,
    getPlans,
    initialPayment,
} from "../services/ApiActions/subscriptionAct";
import Toast from "../services/ToastMessage";
import { useLocation } from "react-router-dom";

const { Header, Content, Footer } = Layout;

const headerStyle: React.CSSProperties = {
    color: "#000",
    height: 64,
    paddingInline: 50,
    backgroundColor: "#fff",
    position: "sticky",
    top: 0,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    boxShadow:
        "0px 0px 0px 0px rgba(0, 0, 0, 0.10), 0px 1px 2px 0px rgba(0, 0, 0, 0.10), 0px 3px 3px 0px rgba(0, 0, 0, 0.09), 0px 8px 5px 0px rgba(0, 0, 0, 0.05), 0px 14px 5px 0px rgba(0, 0, 0, 0.01), 0px 21px 6px 0px rgba(0, 0, 0, 0.00)",
};

const contentStyle = {
    minHeight: "calc(100vh - 64px)",
    color: "#000",
    backgroundColor: "#fff",
    paddingInline: 50,
    paddingTop: 20,
    paddingBottom: 20,
    //   minWidth:'100%'
};

const planTitle: React.CSSProperties = {
    color: "#000",
    fontSize: 24,
    fontWeight: 700,
};

type featureType = {
    pagesPerPDF: number;
    dataPerPDF: string;
    pdfsPerDay: number;
    questionsPerDay: number;
};

type planListType = {
    planName: string;
    planId: string;
    amount: number;
    planType: string;
    planFeatures: featureType;
};

const SubscriptionPlans = () => {
    const navigate = useNavigate();
    const { plan_id } = useParams();
    const [isLoading, setLoading] = useState(false);
    const [planList, setPlanList] = useState<planListType[]>([]);
    const [planDetails, setPlanDetails] = useState<planListType>({} as planListType)

    const onPayNow = () => {
        setLoading(true);
        initialPayment({ plan_id: planDetails?.planId})
            .then((res: any) => {
                // if(res.status===200){
                // window.location.replace(res.payLink);
                // window.open(res.payLink, "_blank")
                // }
                window.open(res.payLink, "_self", "noopener, noreferrer");
                setLoading(false);
            })
            .catch((error: any) => {
                setLoading(false);
                Toast({
                    type: "error",
                    message: error?.message || "Something went wrong",
                });
            });
    };

    useEffect(() => {
        if (plan_id === undefined) {
            getPlans()
                .then((res: any) => {
                    setPlanList([...res]);
                })
                .catch((error) => {
                    console.log("error", error);
                });
        }
    }, [plan_id === undefined]);

    useEffect(() => {
        if (plan_id !== undefined) {
            getPlanDetails(plan_id as string).then((res) => {
                console.log(res)
                setPlanDetails(res as planListType)
            }).catch((error) => {
                Toast({ type: "error", message: "Something went wrong" })
            })
        }
    }, [plan_id])

    return (
        <Layout>
            <Header style={headerStyle}>
                <Logo />
            </Header>
            <Content style={contentStyle}>
                <Spin spinning={isLoading}>
                    {plan_id ? (
                        <div
                            className="d-flex align-item-center"
                            style={{
                                justifyContent: "center",
                                minHeight: "calc(100vh - 104px)",
                            }}
                        >
                            <Card style={{ minWidth: "300px" }}>
                                <h4 style={planTitle}>{planDetails?.planName}</h4>
                                <s>&#8377; 6.99</s>
                                <b>&#8377; 5.99/mo</b>
                                <ul
                                    style={{ paddingLeft: 16, marginTop: 15, marginBottom: 15 }}
                                >
                                    <li>{planDetails?.planFeatures?.pagesPerPDF} pages/PDF</li>
                                    <li>{planDetails?.planFeatures?.dataPerPDF} MB/PDF</li>
                                    <li>{planDetails?.planFeatures?.pdfsPerDay} PDFs/day</li>
                                    <li>{planDetails?.planFeatures?.questionsPerDay} questions/day</li>
                                </ul>
                                <InfoColor>
                                    <Button
                                        shape="round"
                                        block
                                        type="primary"
                                        size="large"
                                        onClick={onPayNow}
                                    >
                                        Pay Now
                                    </Button>
                                </InfoColor>
                            </Card>
                        </div>
                    ) : (
                        <Row>
                            <Col xs={24} sm={12} md={8} lg={6} style={{ display: 'flex', width: '100%' }}>
                                {planList.map((item, id) => (
                                    <Card key={id} style={{ minWidth: 270, border: '1px solid #ddd', margin: 15 }}>
                                        <h4 style={planTitle}>{item.planName}</h4>
                                        <s>&#8377; 6.99</s>
                                        <b> &#8377; 5.99/mo</b>
                                        <ul
                                            style={{
                                                paddingLeft: 16,
                                                marginTop: 15,
                                                marginBottom: 15,
                                            }}
                                        >
                                            <li>{item.planFeatures.pagesPerPDF} pages/PDF</li>
                                            <li>{item.planFeatures.dataPerPDF} MB/PDF</li>
                                            <li>{item.planFeatures.pdfsPerDay} PDFs/day</li>
                                            <li>{item.planFeatures.questionsPerDay} questions/day</li>
                                        </ul>
                                        <InfoColor>
                                            <Button
                                                shape="round"
                                                block
                                                type="primary"
                                                size="large"
                                                onClick={() => navigate(`/plans/${item.planId}`)}
                                            >
                                                Buy Now
                                            </Button>
                                        </InfoColor>
                                    </Card>
                                ))}
                            </Col>
                        </Row>
                    )}
                </Spin>
            </Content>
        </Layout>
    );
};

export default SubscriptionPlans;
