import {
    PlusOutlined,
    FilePdfOutlined,
    EyeOutlined,
    CopyOutlined,
    UserOutlined
} from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../reducers/reduxStore";
import { handelFileModal } from "../reducers/ModalSlice";
import ChatSection from "../components/Layout/ChatSection";
import { useNavigate, useParams } from "react-router-dom";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import {
    getQueryHistory,
    getSubTopicDetails,
    sendQuery,
} from "../services/ApiActions/subTopicAct";
import Toast from "../services/ToastMessage";
import { getTopicDetails } from "../services/ApiActions/topicAct";
import { TopicDataType } from "./TopicChat";
import { FileDataType } from "./SubTopicFilesList";
import { getSubTopicFiles } from "../services/ApiActions/filesAct";
import PDFViewer from "../components/Widget/PDFViewer";
import FileUploadModal from "../components/Widget/FileUploadModal";
import Loader from "../components/Homepage/Loader";
import { Avatar, Spin, Tooltip } from "antd";
import SplitPane from "split-pane-react/esm/SplitPane";
import ProceedSteps from "./ProceedSteps";
import PDF from "../components/Widget/PDF";
import LogoSm from "../icons/LogoSm";

export type SubTopicDataType = {
    id: string;
    name: string;
    update_at: string;
};
type ChatMessagesType = {
    user_id?: string;
    message: string;
    documents: any;
};
type ResponseDoctype = {
    page: number;
    filename: string;
    page_content: string;
    isFullUrl?: boolean
};
type MessageHistoryType = {
    query: string;
    answer: string;
};
type ConversationTypes = {
    text: string;
    username: string;
    type: string;
    raw_resp?: string;
    timestamp: string;
};
type OldMessagesType = {
    id: string;
    conv: ConversationTypes;
};
// function highlightPattern(text: string, pattern: any) {
//     return text.replace(pattern, (value) => `<mark style={{ background: 'red'}}>${value}</mark>`);
// }
const SubTopicChat = () => {
    const { topic, sub_topic } = useParams();
    const navigate = useNavigate();
    const [subTopic, setSubTopic] = useState<SubTopicDataType>();
    const [topicData, setTopicData] = useState<TopicDataType>();
    const [fileList, setFileList] = useState<FileDataType[]>([]);
    const [chatMessages, setChatMessages] = useState<ChatMessagesType[]>([]);
    const [messageHistory, setMessageHistory] = useState<MessageHistoryType[]>([]);
    const [oldMessages, setOldMessages] = useState<OldMessagesType[]>([]);
    const [query, setQuery] = useState("");
    const messagesRef = useRef<HTMLDivElement | null>(null);
    const [isChatEnable, setIsChatEnable] = useState(false);
    const [opneModal, setOpenModal] = useState<ResponseDoctype>({
        filename: "",
        page: 0,
        page_content: "",
    });
    const [loader, setLoader] = useState(false);
    const [loaderOldMessage, setLoaderOldMessage] = useState(true);
    const [isFetchingFile, setFetchingFile] = useState(true);
    const [fileRefetch, setFileRefetch] = useState(false);
    const [lastChatId, setLastChatId] = useState<null | number>(null);
    const { openFileModal } = useSelector((state: RootState) => state.modal);
    const [sizes, setSizes] = useState<(number | string)[]>(["50%", "50%"]);
    const [subTopicStatus, setSubTopicStatus] = useState(false);
    const [pdfData, setPdfData] = useState<ResponseDoctype>({
        filename: "",
        page: 0,
        page_content: "",
        isFullUrl: false
    });

    const dispatch = useDispatch();

    // Closing file modal
    const closeModal = () => dispatch(handelFileModal(false));

    // Getting sub topic details
    useEffect(() => {
        getSubTopicDetails(topic as string, sub_topic as string)
            .then((res: any) => {
                if (res.status === "success") {
                    setSubTopic({ ...res });
                    setSubTopicStatus(true);
                } else if (res.status === 404) {
                    Toast({ type: "error", message: "Sub topic not found" })
                    navigate('/', { replace: true })
                }
            })
            .catch((error) => {
                Toast({
                    type: "error",
                    message: error?.message || "Something went wrong",
                });
            });
    }, [topic, sub_topic]);

    // Getting topic details
    useEffect(() => {
        getTopicDetails(topic as string)
            .then((res: any) => {
                if (res.data.status === "success") {
                    setTopicData({ ...res.data });
                } else if (res.data.status === 404) {
                    Toast({ type: "error", message: "Topic not found" })
                    navigate('/', { replace: true })
                }
            })
            .catch((error) => {
                Toast({
                    type: "error",
                    message: error?.message || "Something went wrong",
                });
            });
    }, [topic]);

    // Checkin files status
    useEffect(() => {
        const fetchFiles = () => {
            getSubTopicFiles(topic as string, sub_topic as string)
                .then((res: any) => {
                    if (res.data.status === "success") {
                        setFileList([...res.data.data]);
                        if (res.data.data.length > 0) {
                            let enableChat = true;
                            for (let item of res.data.data) {
                                if (item.status === "PROCESSING") {
                                    enableChat = false;
                                    break;
                                }
                                if (item.status === "FAILED") {
                                    Toast({
                                        type: "warning",
                                        message:
                                            "We currently only support YouTube videos that are in English",
                                    });
                                }
                            }
                            let fileExt = res.data.data[0].path.split(".")
                            let ext = fileExt[fileExt.length - 1];
                            setPdfData((pre) => ({ page: 0, page_content: "", filename: res.data.data[0].path, isFullUrl: true }));
                            if (enableChat) {
                                clearInterval(fileInterval);
                            }
                            setIsChatEnable(enableChat);
                        } else {
                            clearInterval(fileInterval);
                            setIsChatEnable(false);
                        }
                    } else {
                        setIsChatEnable(false);
                        clearInterval(fileInterval);
                    }
                    setTimeout(() => {
                        setFetchingFile(false)
                    }, 1000)
                })
                .catch((error) => {
                    setFetchingFile(false)
                    clearInterval(fileInterval);
                    setIsChatEnable(false);
                    Toast({
                        type: "error",
                        message: error?.message || "Something went wrong",
                    });
                });
        };

        setFetchingFile(true);
        const fileInterval = setInterval(() => {
            setFetchingFile(true);
            fetchFiles();
        }, 2000);

        // clear message on load
        return () => {
            clearInterval(fileInterval);
            setFileList([]);
            setFetchingFile(false);
        };
    }, [topic, sub_topic, fileRefetch]);

    // getting old messeges
    useEffect(() => {
        setLoaderOldMessage(true);
        getQueryHistory({ topic, sub_topic: sub_topic })
            .then((res: any) => {
                let _history: any[] = [];
                res.history.data.slice(0, 7).forEach((item: any) => {
                    if (item.conv.type === "response") {
                        let jsonRes = JSON.parse(item.conv.raw_resp);
                        _history.push({ query: item.conv.text, answer: jsonRes.answer });
                    }
                });
                const arr: [] = res.history.data;
                const lastChat = Math.max(
                    ...arr.map((item: any) => item.conv.timestamp)
                );

                if (typeof lastChat === "number") {
                    setLastChatId(lastChat);
                }

                setMessageHistory([..._history]);
                setOldMessages([...res.history.data]);

                if (res.history.data[0].conv.type === "response") {
                    let data = JSON.parse(res.history.data[0].conv.raw_resp as string);
                    // uncomment below code when recieve source documents on response
                    if (data.source_documents && data.source_documents.length > 0) {
                        setPdfData({ ...data.source_documents[0], isFullUrl: false });
                    }
                }
                setLoaderOldMessage(false);
            })
            .catch((error) => {
                setLoaderOldMessage(false);
            });

        return () => {
            setOldMessages([]);
            setChatMessages([]);
            setMessageHistory([]);
            setQuery("");
            closePdfViewer();
            setPdfData({ filename: "", page: 0, page_content: "", isFullUrl: false });
        };
    }, [topic, sub_topic]);

    const handleMessageChange = (e: ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        setQuery(value);
    };


    // sending message, getting answer
    const onMessageSend = () => {
        if (query.trim() !== "" && isChatEnable) {
            setQuery("");
            let _histroy = messageHistory;
            if (messageHistory.length > 3) {
                _histroy = messageHistory.slice(0, 3);
            }
            setLoader(true);
            setLastChatId(chatMessages.length);
            setChatMessages((prev: ChatMessagesType[]) => [
                { message: query.trim(), user_id: "test_books", documents: [] },
                ...prev,
            ]);
            sendQuery({
                query: query.trim(),
                filter: {
                    group_id: topic,
                    sub_group_id: sub_topic,
                },

                history: _histroy,
            })
                .then((res: any) => {
                    var newMessage = { user_id: "", message: res.data.answer, documents: res.data.source_documents };
                    // if (res.data.answer.split(",")[0] === "I'm sorry") {
                    //     newMessage.message = res.data.answer;
                    // } else if (res.data.source_documents.length > 0) {
                    //     newMessage.message = res.data.answer;
                    //     newMessage.documents = res.data.source_documents;
                    // } else {
                    //     newMessage.message =
                    //         "I'm sorry, but I couldn't find the answer to your question in the provided context. Could you please rephrase your query with more details or provide additional information?";
                    // }
                    setMessageHistory((prev) => [
                        { query, answer: newMessage.message },
                        ...prev,
                    ]);
                    setLastChatId(chatMessages.length);
                    setChatMessages((prev: ChatMessagesType[]) => [newMessage, ...prev]);
                    setLoader(false);
                    if (res.data.source_documents.length > 0) {
                        setPdfData(res.data.source_documents[0])
                    }
                })
                .catch((error) => {
                    Toast({
                        type: "error",
                        message: error?.message || "Something went wrong",
                    });
                    setLoader(false);
                });
        }
    };

    useEffect(() => {
        document.getElementById(`chat_${lastChatId}`)?.scrollIntoView({
            block: "end",
            behavior: "smooth",
        });
    }, [oldMessages, chatMessages]);

    const openPdf = (item: ResponseDoctype) => {
        setOpenModal(item);
    };

    const onSuccessUpload = (isFileProcessed: boolean) => {
        closeModal();
        Toast({ message: "Files uploaded successfully" });
        if (isFileProcessed) {
            setIsChatEnable(true);
        } else {
            setFileRefetch(!fileRefetch);
            setIsChatEnable(false);
        }
    };

    const closePdfViewer = () => {
        setOpenModal({ filename: "", page: 0, page_content: "" });
    };

    const displayFiles = (document: any) => {
        if (/.pdf/.test(document.filename.toLowerCase())) {
            return (
                <div className="filename" onClick={() => openPdf(document)}>
                    <FilePdfOutlined className="mr-8" />
                    {document.filename}
                </div>
            );
        } else if (/.docx/.test(document.filename.toLowerCase())) {
            return (
                <a
                    href={`${process.env.REACT_APP_S3_URL}/${document.filename}`}
                    target="_blank"
                    className="filename"
                >
                    <EyeOutlined className="mr-8" />
                    {document.filename}
                </a>
            );
        } else {
            return (
                <a href={document.filename} target="_blank" className="filename">
                    <EyeOutlined className="mr-8" />
                    {document.filename}
                </a>
            );
        }
    };


    const copyToClipboard = (text: string) => {
        navigator.clipboard.writeText(text);
        Toast({ message: "Copied clipboard" });
    };

    const leftPanelStyle = {
        height: "100%",
    };

    return (
        <>
            <SplitPane
                style={{
                    minHeight: "100vh",
                    width: "calc(100% - 227px)",
                    marginLeft: "auto",
                }}
                split="vertical"
                sizes={sizes}
                onChange={setSizes}
                sashRender={() => <div className="custom-sash" />}
            >
                <div style={{ height: "100%", overflowY: "auto" }}>
                    {/* {fileList.length === 0 ? (
                        <div style={leftPanelStyle}>
                            <ProceedSteps
                                fileList={fileList}
                                isChatEnable={isChatEnable}
                                subTopicStatus={subTopicStatus}
                                topicStatusfor
                            />
                        </div>
                    ) : (
                        ""
                    )} */}

                    {((oldMessages.length > 0 || fileList.length > 0) && pdfData.filename !== '') ? (
                        <PDF
                            filePageNum={pdfData?.page || 0}
                            fileContent={pdfData?.page_content || ""}
                            file={pdfData.filename}
                            isFullUrl={pdfData.isFullUrl}
                        />
                    ) : (
                        <ProceedSteps
                            fileList={fileList}
                            isChatEnable={isChatEnable}
                            isFileDisabled={isFetchingFile}
                        />
                    )}
                </div>

                <div
                    className="right-panel"
                    style={{
                        background: "#fff",
                        height: "100%",
                    }}
                >
                    <ChatSection
                        topicId={topic}
                        subTopic={sub_topic}
                        headerTitle={subTopic?.name}
                        isQueryDisabled={!isChatEnable}
                        onClickSend={onMessageSend}
                        handleQueryChange={handleMessageChange}
                        query={query}
                        ref={messagesRef}
                        setLoader={setLoader}
                    >
                        {loader ? <Loader /> : ""}
                        {chatMessages.map((item: ChatMessagesType, index: number) => {
                            return (
                                <div
                                    className={`chat-message ${item.user_id === "" ? "message-bot" : "message-user"
                                        }`}
                                    key={index}
                                >
                                    {
                                        (item.user_id === "")
                                            ?
                                            // <div ><LogoSm /></div>
                                            <img src={"/images/logo-sm.png"} alt="Chat bot" />
                                            :
                                            <Avatar
                                                size={36}
                                                shape="square"
                                                icon={<UserOutlined />}
                                            />
                                    }
                                    <div className="query-response">
                                        <p>{item.message}</p>
                                        {item.user_id === "" && (
                                            <div className="d-flex justify-content-between">
                                                {/* {displayFiles(item.documents[0])} */}
                                                <Tooltip placement="bottom" title="Copy Text">
                                                    <button
                                                        className="copy-btn"
                                                        onClick={() => copyToClipboard(item.message)}
                                                    >
                                                        <CopyOutlined />
                                                    </button>
                                                </Tooltip>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            );
                        })}
                        {loaderOldMessage ? <Loader /> : ""}
                        {oldMessages.map((item) => {
                            if (item.conv.type === "response") {
                                let answerRes = JSON.parse(item.conv.raw_resp as string);
                                return (
                                    <div
                                        className={`chat-message message-bot`}
                                        key={item.conv.timestamp}
                                        id={`chat_${item.conv.timestamp}`}
                                    >
                                        {/* <div><LogoSm /></div> */}

                                        <img src={"/images/logo-sm.png"} alt="Chat bot" />
                                        <div className="query-response">
                                            <p>{answerRes.answer}</p>

                                            {answerRes.answer.split(",")[0] !== "I'm sorry" &&
                                                <div className="d-flex justify-content-between">
                                                    {/* {displayFiles(answerRes.source_documents[0])} */}
                                                    <Tooltip placement="bottom" title="Copy Text">
                                                        <button
                                                            className="copy-btn"
                                                            onClick={() => copyToClipboard(answerRes.answer)}
                                                        >
                                                            <CopyOutlined />
                                                        </button>
                                                    </Tooltip>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                );
                            } else {
                                return (
                                    <div
                                        className={`chat-message message-user`}
                                        key={item.conv.timestamp}
                                    >
                                        <Avatar
                                            size={36}
                                            shape="square"
                                            icon={<UserOutlined />}
                                        />
                                        {/* <img src={"/images/user.png"} alt="Chat bot" /> */}
                                        <div className="query-response">
                                            <p>{item.conv.text}</p>
                                        </div>
                                    </div>
                                );
                            }
                        })}

                        {/* ================================================================== */}
                        {isChatEnable ? (
                            <div className="chat-message message-bot">
                                {/* <LogoSm /> */}
                                <img src="/images/logo-sm.png" alt="Chat bot" />
                                <div className="query-response">
                                    <p>
                                        You have successfully uploaded file, now you can start chat...
                                    </p>
                                </div>
                            </div>
                        ) : (fileList.length > 0) && (
                            <div className="chat-message message-bot">
                                {/* <LogoSm /> */}
                                <img src="/images/logo-sm.png" alt="Chat bot" />
                                <div className="query-response">
                                    <p>Your file is under processing wait for a minute</p>
                                </div>
                            </div>
                        )}
                        <div className="chat-message message-bot">
                            {/* <LogoSm /> */}
                            <img src="/images/logo-sm.png" alt="Chat bot" />
                            <div className="query-response">
                                <p>
                                    You have created sub topic <b>{subTopic?.name}</b>. Upload a
                                    file to start....
                                </p>
                            </div>
                        </div>
                        <div className="chat-message message-bot">
                            {/* <LogoSm /> */}
                            <img src="/images/logo-sm.png" alt="Chat bot" />
                            <div className="query-response">
                                <p>
                                    You have successfully created new topic{" "}
                                    <b>{topicData?.name}</b>. Add a sub topic to continue...
                                </p>
                            </div>
                        </div>
                    </ChatSection>
                </div>
            </SplitPane>

            <FileUploadModal
                subTopicName={subTopic?.name as string}
                closeModal={closeModal}
                onSuccessUpload={onSuccessUpload}
                openModal={openFileModal}

            />
            <div>
                {fileList.length === 0 ? (
                    ""
                ) : (
                    <PDFViewer
                        filePageNum={opneModal.page}
                        fileContent={opneModal.page_content}
                        file={opneModal.filename}
                        openModal={opneModal.filename !== ""}
                        closeModal={closePdfViewer}
                    />
                )}
            </div>
        </>
    );
};
export default SubTopicChat;
