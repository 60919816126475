import { rejects } from "assert";
import api from "../api";
import { subscriptionApi } from "../apiVariables";


export const initialPayment = (body: any) => {
    return new Promise((resolve, reject) => {
        api({ ...subscriptionApi.initiatePayment, body })
            .then((res) => {
                resolve(res);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

export const userDetails = () => {
    return new Promise((resolve, reject) => {
        api({ ...subscriptionApi.user_details })
            .then((res) => {
                resolve(res);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

export const updateUserDetails = (body: any) => {
    return new Promise((resolve, reject) => {
        api({ ...subscriptionApi.updateUserProfile, body })
            .then((res) => {
                resolve(res);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

export const getPlans = () => {
    return new Promise((resolve, rejects) => {
        api({ ...subscriptionApi.getPlans })
            .then((res) => {
                resolve(res)
            }).catch((error) => {
                rejects(error)
            })
    })
}

export const getPlanDetails = (plan_id: string) => {
    let { planDetail } = subscriptionApi
    planDetail.plan_id = plan_id;
    return new Promise((resolve, rejects) => {
        api({ ...subscriptionApi.planDetail })
            .then((res) => {
                resolve(res)
            }).catch((error) => {
                rejects(error)
            })
    })
}

export const getLastTransaction = () => {
    return new Promise((resolve, rejects) => {
        api({ ...subscriptionApi.lastTransaction })
            .then((res) => {
                resolve(res)
            }).catch((error) => {
                rejects(error)
            })
    })
}