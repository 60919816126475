import { Button, Input, Layout, Space, theme } from "antd";
import React, { ChangeEvent } from "react";
import { UploadOutlined } from "@ant-design/icons";

type FooterType = {
    handleChange?: (e: ChangeEvent<HTMLInputElement>) => void;
    query?: string;
    isQueryDisabled?: boolean;
    onClickSend?: () => void;
    setLoader?: React.Dispatch<React.SetStateAction<boolean>>;
    className?: string
};

const Footer = ({
    handleChange,
    query,
    isQueryDisabled = true,
    onClickSend,
    setLoader,
    className = ""
}: FooterType) => {
    const {
        token: { colorBgContainer },
    } = theme.useToken();

    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === "Enter") {
            setLoader?.(true);
            // setLoader!(true)
        }
    };

    return (
        <Layout.Footer
            style={{ background: colorBgContainer }}
            className={`message-footer ${className}`}
        >
            <Space.Compact block size="large" color="#fff">
                <Input
                    type="text"
                    placeholder="Ask me anything..."
                    onChange={handleChange}
                    onPressEnter={onClickSend}
                    onKeyDown={handleKeyDown}
                    //   onPressEnter={onClickSendFunction}
                    value={query}
                    disabled={isQueryDisabled}
                />
                <Button
                    icon={<UploadOutlined />}
                    type="default"
                    disabled={isQueryDisabled}
                    onClick={onClickSend}
                />
            </Space.Compact>
        </Layout.Footer>
    );
};

export default Footer;
