import React from "react";
import type { MenuProps } from "antd";
import { Layout, Menu } from "antd";
import { SiderHeaderSetting } from "../SiderHeader";
import Homepage from "../../../../pages/Homepage";
import Sider from "antd/es/layout/Sider";
import { group } from "console";

type MenuItem = Required<MenuProps>["items"][number];

function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[],
  type?: "group"
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
    type,
  } as MenuItem;
}

const items: MenuProps["items"] = [
  getItem("Account", "g1", null, [getItem("Profile", "22")], "group"),

  getItem(
    "Admin",
    "g2",
    null,
    [
      getItem("Members", "22"),
      getItem("Topics", "22"),
      getItem("Documents", "33"),
    ],
    "group"
  ),

  getItem("Analytics", "g3", null, [getItem("Overview", "1")], "group"),

  getItem(
    "Billing",
    "g4",
    null,
    [getItem("Payment Information", "22")],
    "group"
  ),
];

const SettingSidebar: React.FC = () => {
  const onClick: MenuProps["onClick"] = (e) => {
    console.log("click ", e);
  };

  return (
    <Sider className="sidebar-menu">
      <SiderHeaderSetting />
      <Menu
        onClick={onClick}
        style={{ width: 256 }}
        defaultSelectedKeys={["1"]}
        defaultOpenKeys={["g3"]}
        mode="inline"
        items={items}
      />
    </Sider>
  );
};

export default SettingSidebar;
