import { Button } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import BlankMessage from "../components/Homepage/BlankMessage";
import { useDispatch } from "react-redux";
import { hanleAddTopicModal } from "../reducers/ModalSlice";
import { AppDispatch } from "../reducers/reduxStore";
import { InfoColor } from "../components/Colors";
import ChatSection from "../components/Layout/ChatSection";
import ProceedSteps from "./ProceedSteps";
import SplitPane from "split-pane-react/esm/SplitPane";
import { useState } from "react";

const Homepage = () => {
    const dispatch = useDispatch<AppDispatch>();
    const [sizes, setSizes] = useState<(number | string)[]>(["50%", "50%"]);

    const leftPanelStyle = {
        height: "100%",
        overflowY: "auto"
    };

    const rightPanelStyle = {
        border: "1px solid #ddd",
        height: "100%",
    };

    return (
            <SplitPane
                style={{ minHeight: "100vh", width: "calc(100% - 227px)", marginLeft: "auto" }}
                split="vertical"
                sizes={sizes}
                onChange={setSizes}
                sashRender={() => <div className="custom-sash" />}
            >
                <div style={{ height: "100%", overflowY: "auto" }}>
                    <ProceedSteps />
                </div>
                <div className="right-panel">
                    <ChatSection hideHeader>
                        <BlankMessage />
                    </ChatSection>
                </div>
            </SplitPane>
    );
};

export default Homepage;
