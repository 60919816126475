import React, { ChangeEvent, forwardRef } from "react";
import Headers from "./Headers";
import { Layout, theme } from "antd";
import Footer from "./Footer";

type ChatSectionProps = {
    children: React.ReactNode;
    hideHeader?: boolean;
    topicId?: string;
    subTopic?: string;
    headerTitle?: string;
    handleQueryChange?: (e: ChangeEvent<HTMLInputElement>) => void;
    query?: string;
    isQueryDisabled?: boolean;
    onClickSend?: () => void;
    setLoader?: React.Dispatch<React.SetStateAction<boolean>>;
};

const ChatSection = forwardRef(
    (
        {
            children,
            hideHeader = false,
            headerTitle,
            topicId,
            subTopic,
            handleQueryChange,
            query,
            isQueryDisabled,
            onClickSend,
            setLoader,
        }: ChatSectionProps,
        ref: React.Ref<HTMLDivElement>
    ) => {
        const {
            token: { colorBgContainer },
        } = theme.useToken();


        return (
            <>
            
                {!hideHeader && (
                    <Headers
                        hideHeader={hideHeader}
                        title={headerTitle}
                        topicId={topicId}
                        subTopic={subTopic}
                    />
                )}
                <Layout.Content className="layout-content">
                    <div
                        className={`main-chat-content ${hideHeader? 'is-home':''}`}
                        style={{ background: colorBgContainer}}
                    >
                        {children}
                    </div>
                    <div ref={ref} />
                </Layout.Content>
                <Footer
                    handleChange={handleQueryChange}
                    query={query}
                    isQueryDisabled={isQueryDisabled}
                    onClickSend={onClickSend}
                    setLoader={setLoader}
                    // className={topicId === undefined ? "" : 'mt-50'}
                />
            </>
        );
    }
);

export default ChatSection;
