import { Button, Spin } from 'antd'
import React, { useEffect, useState } from 'react'
import { Navigate, useNavigate, useParams } from 'react-router'
import { useSearchParams } from 'react-router-dom';
import { verifyEmailApi } from '../../services/ApiActions/authAction';
import Toast from '../../services/ToastMessage';
import Authorization from '../../helper/Authorization';

const SuccessPage = () => {
    const { type } = useParams();
    const navigate = useNavigate();
    const [urlSearchParams] = useSearchParams();
    const [isLoading, setLoading] = useState(false);

    useEffect(() => {
        if (urlSearchParams.has("token") && urlSearchParams.get("token") !== "") {
            setLoading(true);
            verifyEmailApi({ token: urlSearchParams.get("token") }).then((res: any) => {
                if (res.status === 200) {
                    let webSource = localStorage.getItem('websiteSource') !== null ? JSON.parse(localStorage.getItem('websiteSource') as string) : null;
                    if (webSource !== null && webSource.planType == 2) {
                        localStorage.removeItem('websiteSource');
                        navigate("/plans", { replace: true });
                    } else {
                        navigate("/", { replace: true });
                    }
                    Toast({ message: "Email verified successfully"})
                    Authorization.login({ token: res.token, username: res.username });
                }
                setLoading(false);
            }).catch((error) => {
                setLoading(false);
                Toast({ type: 'error', message: error?.message || "Something went wrong" })
            })
        } else {
            navigate('/login', { replace: true })
        }
    }, [type === "signup"])
    return (
        <div
            className="auth-layout"
            style={{ backgroundImage: "url(/images/auth-bg.png)" }}
        >
            {
                (type === 'reset')
                    ?
                    <>
                        <h2 className='success-head'>"Congratulations! Your account has been </h2>
                        <h2 className='success-head mb-30'>successfully updated!</h2>
                        <Button
                            type='primary'
                            size='large'
                            style={{ borderRadius: 2, paddingInline: 20 }}
                            onClick={() => navigate('/login', { replace: true })}
                        >Login Now</Button>
                    </>
                    : (type === 'signup') ?
                        <>
                            {
                                isLoading ?
                                    <Spin />
                                    :
                                    <>
                                        <h2 className='success-head'>Verification complete!</h2>
                                        <h2 className='success-head mb-30'>You're all set to continue!</h2>
                                        <p className='success-paragraph mb-16'>Congratulations! You've successfully verified your account!<br /> Click the button below to continue.</p>
                                        <Button
                                            type='primary'
                                            size='large'
                                            style={{ borderRadius: 2, paddingInline: 20 }}
                                            onClick={() => navigate('/', { replace: true })}
                                        >Continue to Gist</Button>
                                    </>
                            }
                        </>
                        :
                        <Navigate to={'/login'} replace={true} />
            }
            <div
                style={{
                    display: "flex",
                    position: "absolute",
                    flexDirection: "column",
                    alignItems: "center",
                    bottom: "0",
                }}
            >
                {" "}
                <span>©AIFISE</span> <span>Terms of use | Privacy Policy</span>
            </div>

            {/* </div> */}
        </div>
    )
}

export default SuccessPage