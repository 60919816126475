import React, { useEffect, useState } from "react";
import ContentSection from "../components/Layout/ContentSection";
import type { ColumnsType } from "antd/es/table";
import { Button, Popconfirm, Table, Tooltip } from "antd";
import { DeleteOutlined, FileAddOutlined, FolderAddOutlined } from "@ant-design/icons";
import { useNavigate, useParams } from "react-router-dom";
import { getSubTopicDetails } from "../services/ApiActions/subTopicAct";
import Toast from "../services/ToastMessage";
import { SubTopicDataType } from './SubTopicChat';
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../reducers/reduxStore";
import { handelFileModal } from "../reducers/ModalSlice";
import {
    deleteSubtopicFile,
    getSubTopicFiles,
} from "../services/ApiActions/filesAct";
import FileUploadModal from "../components/Widget/FileUploadModal";
import SlitePanel from "../components/SplitePanel";
import PDF from "../components/Widget/PDF";

interface DataType {
    id: string;
    key: string;
    number: number;
    name: string;
    path: string;
    size: string;
    updated_at: string;
}

export type FileDataType = {
    id: string;
    name: string;
    path: string;
    updated_at: string;
};

const SubTopicFilesList = () => {
    const { topic, sub_topic } = useParams();
    const [fileList, setFileList] = useState<DataType[]>([]);
    const [subTopicData, setSubTopicData] = useState<SubTopicDataType>();
    const { openFileModal } = useSelector((state: RootState) => state.modal);
    const [reFetch, setReFetch] = useState(false);
    const [isFetchingFile, setFetchingFile] = useState(false)
    const dispatch = useDispatch<AppDispatch>();
    const [pdfName, setPdfName] = useState("")

    const navigate = useNavigate()

    /**
     * File list table columns
     */
    const columns: ColumnsType<DataType> = [
        { title: "S.No.", dataIndex: "number", key: "s.no." },
        { title: "Document name", dataIndex: "name", key: "name" },
        {
            title: "Document type",
            dataIndex: "path",
            key: "type",
            className: "text-uppercase",
            render: (filename) => {
                if (/dev-gist-chatbot-app-assets/.test(filename)) {
                    let file = filename.split(".");
                    return file[file.length - 1];
                } else if (/youtube/.test(filename) || /youtu.be/.test(filename)) {
                    let file = filename.split(".");
                    return "youtube url";
                } else {
                    return "Web Url";
                }
            },
        },
        // { title: 'Size', key: 'size', dataIndex: 'size' },
        // {
        //     title: 'Upload Date', dataIndex: 'updated_at', key: 'uploaded_at',
        //     render: (date) => moment.unix(date).format('MM/DD/YYYY')
        // },
        {
            title: "Delete",
            key: "Delete",
            render: (_, record) => (
                <Popconfirm
                    title="Delete the File"
                    description="Are you sure to delete this File?"
                    onConfirm={() => onConfirmDelete(record.id)}
                    okText="Yes"
                    cancelText="No"
                    placement="topRight"
                >
                    <Button danger type="text" icon={<DeleteOutlined />} />
                </Popconfirm>
            ),
        },
    ];

    /**
     * Fetching sub topic file list based on topic and sub topic
     * @dependency { string } topic
     * @dependency { string } sub_topic
     * @dependency { boolean } reFetch ( to refetch the list after adding or removing a file)
     */
    useEffect(() => {
        setFetchingFile(true)
        getSubTopicFiles(topic as string, sub_topic as string)
            .then((res: any) => {
                let _newData: DataType[] = res.data.data.map(
                    (item: FileDataType, index: number) => ({
                        ...item,
                        key: item.id,
                        size: "47.4 KB",
                        number: index + 1,
                    })
                );
                setFileList([..._newData]);
                if (res.data.data.length > 0) {
                    setPdfName(res.data.data[0].path)
                } else {
                    setPdfName("")
                }
                setFetchingFile(false)

            })
            .catch((error) => {
                setFetchingFile(false)
                Toast({
                    type: "error",
                    message: error?.message || "Something went wrong",
                });
            });
    }, [topic, sub_topic, reFetch]);



    /**
     * Fetching sub topic deatils based on topic and sub topic
     * @dependency { string } topic
     * @dependency { string } sub_topic
     */
    useEffect(() => {
        getSubTopicDetails(topic as string, sub_topic as string)
            .then((res: any) => {
                if (res.status === "success") {
                    setSubTopicData({ ...res });
                } else if (res.status === 404) {
                    Toast({ type: "error", message: "Sub topic not found" })
                    navigate('/', { replace: true })
                }
            })
            .catch((error) => {
                Toast({
                    type: "error",
                    message: error?.message || "Something went wrong",
                });
            });
    }, [topic, sub_topic]);

    /**
     * To close add file modal
     */
    const closeModal = () => {
        dispatch(handelFileModal(false));
    };

    /**
     * After confirm delete a file
     * @param { stirng } id ( file id )
     */
    const onConfirmDelete = (id: string) => {
        deleteSubtopicFile(topic as string, sub_topic as string, id)
            .then((res: any) => {
                if (res.resp.status === 'success') {
                    setReFetch(!reFetch);
                    Toast({ message: "File removed successfully" });
                    console.log("000000")
                    navigate(`/chat/${topic}/${sub_topic}`)
                    
                } else {
                    Toast({
                        type: "error",
                        message: res?.message || "Something went wrong",
                    });
                }
            })
            .catch((error) => {
                Toast({
                    type: "error",
                    message: error?.message || "Something went wrong",
                });
            });
    };

    const onSuccessFillUpload = () => {
        setReFetch(!reFetch);
        closeModal();
        Toast({ message: "Files uploaded successfully" });
    };

    return (
        <SlitePanel>
            <SlitePanel.LeftPane>
                {
                    (fileList.length > 0) ?
                        <PDF
                            file={pdfName}
                            fileContent=""
                            filePageNum={0}
                            isFullUrl
                        />
                        :
                        <div className="left-panel">
                            <p style={{ fontSize: 26, fontWeight: 500 }}>
                                Pro Tips for Streamlined PDF Chats
                            </p>
                            <p>
                                We're here to help you effortlessly manage your files as you engage with
                                your PDFs!
                            </p>
                            <div
                                className="chat-message-upload"
                                style={{
                                    marginTop: 40,
                                    display: "flex",
                                    flexDirection: "column",
                                }}
                            >
                                <p>Finally, proceed to upload your PDFs</p>
                                <button
                                    //   disabled={!subTopicStatus}
                                    disabled={!(sub_topic !== undefined) || isFetchingFile}
                                    className="add-subtopic-btn"
                                    onClick={() => dispatch(handelFileModal(true))}
                                >
                                    <FolderAddOutlined className="fs-18" />
                                    Upload files
                                </button>
                            </div>
                        </div>
                }
            </SlitePanel.LeftPane>
            <SlitePanel.RightPane>
                <ContentSection headerTitle={subTopicData?.name} topic={topic}>
                    <div className="d-flex justify-content-end mb-16">
                        {/* <Tooltip placement="topRight" title="Add files">
                            <Button onClick={() => dispatch(handelFileModal(true))}>
                                <FileAddOutlined />
                            </Button>
                        </Tooltip> */}
                    </div>
                    <Table columns={columns} dataSource={fileList} />
                </ContentSection>
                <FileUploadModal
                    subTopicName={subTopicData?.name as string}
                    openModal={openFileModal}
                    closeModal={closeModal}
                    onSuccessUpload={onSuccessFillUpload}
                />
            </SlitePanel.RightPane>
        </SlitePanel>
    );
};

export default SubTopicFilesList;
