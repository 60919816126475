import { Card, Col, Row } from "antd";
import React from "react";
import LogoSm from "../../icons/LogoSm";

type BlankCardProps = {
  title: string;
  img: string;
  description: React.ReactNode;
};

const BlankMessage = () => {
  return (
    <div className="">
      <div className="text-center">
        <img src="/images/logo-sm.png" className="m-auto d-block mb-8" alt="Gist logo" />
        {/* <LogoSm /> */}
        <p
          style={{
            fontSize: 22,
            fontWeight: 500,
          }}
        >
          Welcome to Gist
        </p>
        <p
          style={{
            width: "400px",
            margin: "auto",
          }}
        >
          Your PDF AI - Your AI Chat Companion, Customized for PDFs. Delivering
          Complimentary Summaries and Answers to Your Inquiries.
        </p>
      </div>
      <Col
        // gutter={[16, 16]}
        className="mt-30"
      >
        <Row style={{ marginBottom: 5 }} gutter={[0, 2]}>
          <BlankCard
            title="For Students"
            img="/images/icons/student.svg"
            description=" Prepare for exams, get help with homework and answer multiple choice questions."
          />
        </Row>
        <Row style={{ marginBottom: 5 }}>
          <BlankCard
            title="For Researchers"
            img="/images/icons/researcher.svg"
            description="Scientific papers, academic articles and books. Get the information you need for your research."
          />
        </Row>
        <Row style={{ marginBottom: 5 }}>
          <BlankCard
            title="For Professionals"
            img="/images/icons/professional.svg"
            description="Legal contracts, financial reports, manuals and training material. Ask any question to any PDF."
          />
        </Row>
      </Col>
    </div>
  );
};

const BlankCard = ({ title, img, description }: BlankCardProps) => {
  return (
    <Card className="home-blank-mes-card">
      <h5 className="d-flex align-item-center">
        <img src={img} className="mr-8" alt="icon" />
        {title}
      </h5>
      <p>{description}</p>
    </Card>
  );
};

export default BlankMessage;
