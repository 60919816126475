import React, { useEffect, useState } from "react";
import ContentSection from "../components/Layout/ContentSection";
import type { ColumnsType } from "antd/es/table";
import { Button, Popconfirm, Table, Tooltip } from "antd";
import { Link, useNavigate, useParams } from "react-router-dom";
import { DeleteOutlined, FolderAddOutlined } from "@ant-design/icons";
import {
    deleteSubtopic,
    getSubtopicList,
} from "../services/ApiActions/subTopicAct";
import Toast from "../services/ToastMessage";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { getTopicDetails } from "../services/ApiActions/topicAct";
import { TopicDataType } from "./TopicChat";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../reducers/reduxStore";
import { forceReFetchMenulist, handelFileModal, handleSubTopicModal } from "../reducers/ModalSlice";
import SlitePanel from "../components/SplitePanel";
import PDF from "../components/Widget/PDF";
import { getSubTopicFiles } from "../services/ApiActions/filesAct";
import ProceedSteps from "./ProceedSteps";
import FileUploadModal from "../components/Widget/FileUploadModal";

interface DataType {
    key: string;
    number: number;
    id: string;
    name: string;
    updated_at: string;
    size: string;
    // dataTypes:{
    //   id:string;
    //   name:string;
    //   created_at:string;
    //   updated_at:string
    // }[];
    // last_index:any;
    // status:string;
    // type:string
}

const SubTopicList = () => {
    const { topic, sub_topic } = useParams();
    const [subTopicList, setSubTopicList] = useState<DataType[]>([]);
    const [topicData, setTopicData] = useState<TopicDataType>();
    const [reFetch, setReFetch] = useState(false);
    const [reFetchFiles, setReFetchFiles] = useState(false);
    const [isFetchingFile, setFetchingFile] = useState(true);
    const [pdfName, setPdfName] = useState("");
    const [subtopicName, setSubtopicName] = useState("");
    const navigate = useNavigate();

    const { reFetchingMenu, openFileModal } = useSelector((state: RootState) => state.modal);
    const dispatch = useDispatch<AppDispatch>();

    const columns: ColumnsType<DataType> = [
        {
            title: "S.No.",
            dataIndex: "number",
            key: "s.no.",
        },
        {
            title: "Sub Topic Name",
            dataIndex: "name",
            key: "name",
            render: (text, record) => (
                <Tooltip placement="top" title="Click to upload file">
                    <Link to={`/topic/${topic}/${record.id}/files`}>{text}</Link>
                </Tooltip>
            ),
        },
        {
            title: "Delete",
            key: "delete",
            render: (_, record) => (
                <Popconfirm
                    title="Delete the Sub topic"
                    description="Are you sure to delete this Sub topic?"
                    onConfirm={() => onConfirmDelete(record.id)}
                    okText="Yes"
                    cancelText="No"
                    icon={<QuestionCircleOutlined style={{ color: "red" }} />}
                >
                    <Button danger type="text" icon={<DeleteOutlined />} />
                </Popconfirm>
            ),
        },
    ];

    useEffect(() => {
        getSubtopicList(topic as string)
            .then((res: any) => {
                let _newData: DataType[] = res.data.data.map((item: any, index: number) => ({
                    ...item,
                    number: index + 1,
                    size: "47.4 KB",
                    key: item.id,
                }));
                setSubTopicList([..._newData]);
                if (res.data.data.length > 0) {
                    setSubtopicName(res.data.data[0].name)
                }
            })
            .catch((error) => {
                Toast({
                    type: "error",
                    message: error?.message || "Something went wrong",
                });
            });
    }, [topic, reFetch]);


    useEffect(() => {
        if (sub_topic === undefined) {
            return;
        }
        setFetchingFile(true);
        getSubTopicFiles(topic as string, sub_topic as string)
            .then((res: any) => {
                if (res.data.data.length > 0) {
                    setPdfName(res.data.data[0].path);
                }
                setFetchingFile(false)
            })
            .catch((error) => {
                setFetchingFile(false)
                Toast({
                    type: "error",
                    message: error?.message || "Something went wrong",
                });
            });
    }, [topic, sub_topic, reFetchFiles]);

    useEffect(() => {
        getTopicDetails(topic as string)
            .then((res: any) => {
                if (res.data.status === "success") {
                    setTopicData({ ...res.data });
                } else if (res.data.status === 404) {
                    Toast({ type: "error", message: "Topic not found" })
                    navigate('/', { replace: true })
                }
            })
            .catch((error) => {
                Toast({
                    type: "error",
                    message: error?.message || "Something went wrong",
                });
            });
    }, [topic]);

    const onConfirmDelete = (id: string) => {
        deleteSubtopic(topic as string, id)
            .then((res: any) => {
                if (res.data.status === 200) {
                    setReFetch(!reFetch);
                    dispatch(forceReFetchMenulist(!reFetchingMenu));
                    Toast({ message: "Sub topic removed successfully" });
                }
            })
            .catch((error) => {
                Toast({
                    type: "error",
                    message: error?.message || "Something went wrong",
                });
            });
    };

    const closeModal = () => dispatch(handelFileModal(false));

    const onSuccessUpload = (isFileProcessed: boolean) => {
        closeModal();
        setReFetchFiles(!reFetchFiles)
        Toast({ message: "Files uploaded successfully" });
    };

    return (
        <>
            <SlitePanel>
                <SlitePanel.LeftPane>
                    {
                        (pdfName === "") ?
                            <ProceedSteps
                                hideCreateTopicBtn
                                isSubtopiDisabled={sub_topic !== undefined}
                                isFileDisabled={(sub_topic === undefined || isFetchingFile)}
                            />
                            :
                            <PDF
                                file={pdfName}
                                fileContent=""
                                filePageNum={0}
                                isFullUrl
                            />
                    }
                </SlitePanel.LeftPane>
                <SlitePanel.RightPane>
                    <ContentSection topic={topic} subTopic={sub_topic} headerTitle={topicData?.name}>
                        <Table columns={columns} dataSource={subTopicList} pagination={false} />
                    </ContentSection>
                </SlitePanel.RightPane>
            </SlitePanel>
            <FileUploadModal
                subTopicName={subtopicName}
                closeModal={closeModal}
                onSuccessUpload={onSuccessUpload}
                openModal={openFileModal}
            />
        </>
    );
};

export default SubTopicList;
