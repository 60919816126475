import { Button, Card, Col, Form, Input, Layout, Modal, Row, Spin } from 'antd'
import React, { useState } from 'react'
import Logo from '../../icons/Logo';
import { EMAIL_REGEX } from '../../constant';
import { InfoColor } from '../../components/Colors';
import api from '../../services/api';
import Toast from '../../services/ToastMessage';
import { useNavigate } from 'react-router-dom';
const { Header, Content, Footer } = Layout;

const headerStyle: React.CSSProperties = {
    color: "#000",
    height: 64,
    paddingInline: 50,
    backgroundColor: "#fff",
    position: "sticky",
    top: 0,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    zIndex: 9999,
    boxShadow:
        "0px 0px 0px 0px rgba(0, 0, 0, 0.10), 0px 1px 2px 0px rgba(0, 0, 0, 0.10), 0px 3px 3px 0px rgba(0, 0, 0, 0.09), 0px 8px 5px 0px rgba(0, 0, 0, 0.05), 0px 14px 5px 0px rgba(0, 0, 0, 0.01), 0px 21px 6px 0px rgba(0, 0, 0, 0.00)",
};

const contentStyle = {
    minHeight: "calc(100vh - 64px)",
    color: "#000",
    backgroundColor: "#fff",
    paddingInline: 50,
    paddingTop: 20,
    paddingBottom: 20,
    //   minWidth:'100%'
};

type FieldType = {
    fullname?: string;
    email?: string;
    contact?: string;
    message?: string;
};

const Contact = () => {
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const [isLoading, setLoading] = useState(false)

    const onFinish = (data: any) => {
        setLoading(true)
        api({ api: '/contact/create', method: "post", body: data }).then((res: any) => {
            setLoading(false)
            form.resetFields();
            Modal.success({
                content:
                    <>
                        <h3>Thank you for contacting us!</h3>
                        <p>We will contact you shortly.</p>
                    </>,
                onOk: () => navigate("/")
            })
        }).catch((error) => {
            setLoading(false)
            Toast({ type: "error", message: error?.message || "Something went wrong" })
        })
    }

    return (
        <Layout>
            <Header style={headerStyle}>
                {/* <Logo /> */}
                <img src="/images/logo.png" alt="Gist" />
            </Header>
            <Content style={contentStyle}>
                <Row>
                    <Col xs={24} md={12} className="d-flex" style={{ flexDirection: "column", justifyContent: 'space-evenly' }}>
                        <div>
                            <h2
                                className={"text-4xl fs-36 "}
                                style={{ lineHeight: 1 }}
                            >
                                AIFISE
                            </h2>
                            <h2
                                className={"text-4xl  fs-36 font-bold "}
                                style={{ lineHeight: 1 }}
                            >
                                is here to assist you!
                            </h2>
                            <p
                                className={
                                    "contact-content fs-20"
                                }
                                style={{ lineHeight: 1.2, marginTop: 16 }}
                            >
                                We are always happy to hear from customers, users. Feel free
                                to contact us anytime!
                            </p>
                        </div>
                        {/* <div>
                            <p
                                className={
                                    "text-lg grey80 lg:text-2xl mb-3 lg:w-9/12 "
                                }
                            >
                                You can also reach out to us at
                            </p>
                        </div> */}
                    </Col>
                    <Col xs={0} md={3}></Col>
                    <Col xs={24} md={9} className="mt-4">
                        <Card className="contact-form-card">
                            <div className={"text-center font-bold fs-24 "} style={{ fontWeight: "bold" }}>
                                Get in touch with us!
                            </div>
                            <Spin spinning={isLoading}>
                                <Form
                                    style={{ paddingTop: "12px" }}
                                    layout="vertical"
                                    form={form}
                                    onFinish={onFinish}
                                    disabled={isLoading}
                                >
                                    <Form.Item<FieldType>
                                        label="Fullname"
                                        style={{ marginBottom: 12 }}
                                        name="fullname"
                                        rules={[
                                            { required: true, message: "Please enter your fullname" },
                                        ]}
                                    >
                                        <Input
                                            size="large"
                                            className="chat-bot-input"
                                            placeholder="Email Address"
                                        />
                                    </Form.Item>
                                    <Form.Item<FieldType>
                                        label="Email"
                                        style={{ marginBottom: 12 }}
                                        name="email"
                                        rules={[
                                            { required: true, message: "Please enter your email" },
                                            { pattern: EMAIL_REGEX, message: "Please enter a valid email" }
                                        ]}
                                    >
                                        <Input
                                            size="large"
                                            className="chat-bot-input"
                                            placeholder="Email Address"
                                        />
                                    </Form.Item>
                                    <Form.Item<FieldType>
                                        label="Contact No"
                                        style={{ marginBottom: 12 }}
                                        name="contact"
                                        rules={[
                                            { required: true, message: "Please enter your contact no" },
                                            { pattern: /^([0|\+[0-9]{1,5})?([6-9][0-9]{9})$/, message: "Please enter a valid contact number" }
                                        ]}
                                    >
                                        <Input
                                            size="large"
                                            className="chat-bot-input"
                                            placeholder="Email Address"
                                        />
                                    </Form.Item>
                                    <Form.Item<FieldType>
                                        label="Message"
                                        style={{ marginBottom: 12 }}
                                        name="message"
                                        rules={[
                                            { required: true, message: "Please enter your message" },
                                        ]}
                                    >
                                        <Input.TextArea
                                            size="large"
                                            className="chat-bot-input"
                                            placeholder="Email Address"
                                            rows={3}
                                        />
                                    </Form.Item>
                                    <InfoColor>
                                        <Button
                                            size='large'
                                            type="primary"
                                            shape='round'
                                            htmlType="submit"
                                            className='d-block ml-auto'
                                        >
                                            Submit
                                        </Button>
                                    </InfoColor>
                                </Form>
                            </Spin>
                        </Card>
                    </Col>
                </Row>
            </Content>
        </Layout>
    )
}

export default Contact