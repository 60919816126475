import React, { useState } from 'react'
import AuthLayout from '../../components/Layout/AuthLayout'
import { Button, Form, Input, Spin } from 'antd'
import Paragraph from 'antd/es/typography/Paragraph'
import { EMAIL_REGEX } from '../../constant'
import { Link } from 'react-router-dom'
import { forgetPassword } from '../../services/ApiActions/authAction'
import Toast from '../../services/ToastMessage'

type FieldType = {
    username?: string;
};


const ForgetPassword = () => {
    const [form] = Form.useForm();
    const [isLoading, setLoading] = useState(false);
    const [fEmail, setEmail] = useState("");

    const onForgetPassword = (data: any) => {
        setLoading(true);
        forgetPassword(data).then((res: any) => {
            setLoading(false)
            if (res.status === 200) {
                setEmail(data.username);
                Toast({ message: res.message })
            }
        }).catch((error: any) => {
            setLoading(false)
            Toast({ type: "error", message: error?.message || "Something went wrong" })
        })
    }

    return (
        <AuthLayout>
            <Spin spinning={isLoading}>
                {
                    fEmail === "" ?
                        <>
                            <h1>Forgot your Password?</h1>
                            <Paragraph className="mb-6">
                                Not to worry. Simply enter your email address below.
                            </Paragraph>
                            <Form
                                style={{ paddingTop: "12px" }}
                                layout="vertical"
                                form={form}
                                onFinish={onForgetPassword}
                            >
                                <Form.Item<FieldType>
                                    label="Email"
                                    style={{ marginBottom: 12 }}
                                    name="username"
                                    rules={[
                                        { required: true, message: "Please enter your email" },
                                        { pattern: EMAIL_REGEX, message: "Please enter a valid email" }
                                    ]}
                                >
                                    <Input
                                        size="large"
                                        className="chat-bot-input"
                                        placeholder="Email Address"
                                    />
                                </Form.Item>
                                <Button
                                    size="large"
                                    block
                                    style={{ borderRadius: "2px", marginTop: 12 }}
                                    type="primary"
                                    htmlType="submit"
                                >
                                    Sent
                                </Button>
                            </Form>
                            <div className='auth-return-link'>
                                Return to <Link to="/login">Log in</Link>
                            </div>
                        </>
                        :
                        <>
                            <h1>Check your mail</h1>
                            <Paragraph className="mb-6">
                                Please check your email. You'll find instructions on resetting your password at abc@gmail.com.
                            </Paragraph>
                            <Button
                                size="large"
                                block
                                style={{ borderRadius: "2px", margin: "16px 0px" }}
                                type="primary"
                                onClick={() => onForgetPassword({ username: fEmail })}
                            >
                                Resend email
                            </Button>
                            <Paragraph className='mt-6 mb-0'>
                                If you haven't received an email within five minutes, please check your spam folder or consider using a different email address.
                            </Paragraph>
                        </>
                }
            </Spin>
        </AuthLayout>
    )
}

export default ForgetPassword