import { Button, Form, Modal, Space } from "antd";
import React from "react";
import { InfoColor } from "../Colors";

type FormModalProps = {
    openModal: boolean;
    title: React.ReactNode;
    onCancelClick: () => void;
    onOkClick: () => void;
    children: React.ReactNode;
    okText?: React.ReactNode;
};

const FormModal = ({
    openModal,
    title,
    onCancelClick,
    onOkClick,
    children,
    okText,
}: FormModalProps) => {
    return (
        <Modal
            title={title}
            centered
            open={openModal}
            footer={
                <ModalFooter
                    onOkClick={onOkClick}
                    okText={okText}
                    onCancelClick={onCancelClick}
                />
            }
            onCancel={onCancelClick}
            className="topic-modal"
            maskClosable={false}
            keyboard={false}
        >
            <div className="modal-content">{children}</div>
        </Modal>
    );
};

type ModalFooterProps = {
    onCancelClick?: () => void;
    onOkClick?: () => void;
    okText?: React.ReactNode;
    cancelText?: React.ReactNode;
};

export const ModalFooter = ({
    onCancelClick,
    onOkClick,
    okText = "Create",
    cancelText = "Cancel",
}: ModalFooterProps) => {
    return (
        <Space>
            <Button className="border-rounded-0" onClick={onCancelClick}>
                {cancelText}
            </Button>
            <InfoColor>
                <Button type="primary" className="border-rounded-0" onClick={onOkClick}>
                    {okText}
                </Button>
            </InfoColor>
        </Space>
    );
};

type ProfileModalProps = {
    openModal: boolean,
    closeModal: () => void,
    children: React.ReactNode
}

export const ProfileModal = ({ openModal, closeModal, children }: ProfileModalProps) => {
    return (
        <Modal
            title="Upload files"
            centered
            open={openModal}
            footer={null}
            className="topic-modal"
            maskClosable={false}
            keyboard={false}
            closeIcon={
                <div onClick={closeModal} aria-label="close">
                    X
                </div>
            }
        >
            <Form>
                <div className="modal-content">
                    {children}
                </div>
                <div className="ant-modal-footer">
                    <Button
                        type="default"
                        onClick={closeModal}
                        className="border-rounded-2"
                    >
                        Cancel
                    </Button>
                    <InfoColor>
                        <Button
                            type="primary"
                            htmlType="submit"
                            className="border-rounded-2"
                        >
                            Save
                        </Button>
                    </InfoColor>
                </div>
            </Form>
        </Modal>
    )
}

export default FormModal;
