import { Button } from 'antd'
// import React from 'react'
// import GmailIcon from '../../icons/GmailIcon'
// import NewTabIcon from '../../icons/NewTabIcon'
// import { Link } from 'react-router-dom'
import { ArrowLeftOutlined } from '@ant-design/icons'
import { useNavigate } from 'react-router-dom'

const EmailSent = () => {
    const navigate = useNavigate();
    
    return (
        <div className="auth-layout">
            <h2 className='success-head mb-30'>Check your inbox for a verification email!</h2>
            <p className='success-paragraph mb-16'>We've sent an email verification request to your registered email address.<br /> Simply verify to unlock and enjoy all the services of Gist!</p>
            {/* <Button
                type='link'
                size='large'
                className='gmail-btn'
            >
                <GmailIcon className="mr-10" style={{ height: 16 }} />
                Open Gmail
                <NewTabIcon className="ml-10" style={{ height: 14 }} />
            </Button> */}
            <Button
                type='primary'
                size='large'
                style={{ borderRadius: 4, paddingInline: 20 }}
                onClick={() => navigate('/login', { replace: true })}
            >
                <ArrowLeftOutlined />
                Back to Login
            </Button>
            {/* <div className='auth-return-link'>
                Didn’t receive an Email? <Button type='text' style={{ padding: 0, color: "#0B8AD9" }}>Resend</Button>
            </div> */}
            <div
                style={{
                    display: "flex",
                    position: "absolute",
                    flexDirection: "column",
                    alignItems: "center",
                    bottom: "0",
                }}
            >
                {" "}
                <span>©AIFISE</span> <span>Terms of use | Privacy Policy</span>
            </div>

            {/* </div> */}
        </div>
    )
}

export default EmailSent