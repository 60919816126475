import { isString } from "antd/es/button";
import { redirect } from "react-router-dom";

class Authorization {
    authUser = null;

    constructor() {
        this.authUser = null;
    }

    // set auth user details to class property
    setAuthUser() {
        this.authUser = JSON.parse(localStorage.getItem('authDetails') as string);
    }

    /**
     * check active user is logged in
     * @return {boolean}
     */
    isLoggedIn() {
        return typeof localStorage.getItem("authDetails") === "string";
    }

    /**
     * setting logged user's details in localstorage
     * @param userDetails 
     */
    public login(userDetails: any) {
        if (typeof Storage !== undefined) {
            localStorage.setItem('authDetails', JSON.stringify(userDetails));
        } else {
            console.error('local storage is not supported');
        }
    }

    /**
     * clear localstorage when user logout themself
     */
    logout() {
        localStorage.removeItem('authDetails');
    }

    /**
     * getting logged in user's details
     * @returns { Object }
     */
    getAuthUser() {
        if (this.isLoggedIn()) {
            this.setAuthUser();
        }
        return this.authUser;
    }
    /**
     * Get authentication access token
     * @return { string }
     */
    getAccessToken() {
        let accessToken = null;
        const authUser: any = this.getAuthUser();
        if (authUser && isString(authUser.token)) {
            accessToken = authUser.token;
        }

        return accessToken;
    }

    updateAccessToken(newToken:string){
        const authUser:any = this.getAuthUser();
        if(authUser && isString(authUser.token)){
            authUser.token = newToken;
            this.login(authUser);
        }
    }
}

export default new Authorization();