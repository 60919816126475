import { Button, Col, Form, FormInstance, Input, Modal, Row, Spin } from 'antd'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from '../../reducers/reduxStore'
import { updateUserDetails } from '../../services/ApiActions/subscriptionAct'
import { forgetPassword } from '../../services/ApiActions/authAction'
import Toast from '../../services/ToastMessage'
import { setProfileDetails, updateProfileDetails } from '../../reducers/UserSlice'

type UserProfileModalTypes = {
    openModal: boolean,
    closeModal: () => void
}

const UserProfileModal = ({ openModal, closeModal }: UserProfileModalTypes) => {
    const formRef = React.useRef<FormInstance>(null);
    const { profile } = useSelector((state: RootState) => state.userProfile);
    const [isLoading, setLoading] = useState(false)
    const dispatch = useDispatch<AppDispatch>();

    React.useEffect(() => {
        formRef.current?.setFieldsValue({ fullname: profile?.name, email: profile?.username, organization: profile?.organisation })
    }, [openModal === true])

    const onSubmit = (data: any) => {
        console.log(data);

        setLoading(true);
        updateUserDetails(data).then((res: any) => {
            console.log("update", res);
            if (res.result.status === 200) {
                dispatch(updateProfileDetails({ name: res.result.fullname, organisation: res.result.organisation, topic_count: profile.topic_count, subtopic_count: profile.subtopic_count }));
                Toast({ message: "Profile updated successfully" })
                closeModal();
            }
            setLoading(false);
        }).catch((error) => {
            setLoading(false);
            Toast({ type: "error", message: error?.message || "Something went wrong" })
        })
    }

    const resetEmailLink = () => {
        setLoading(true);
        forgetPassword({ username: profile?.username }).then((res: any) => {
            setLoading(false)
            if (res.status === 200) {
                Toast({ message: "We have shared reset password link in you Email" })
            }
        }).catch((error: any) => {
            setLoading(false)
            Toast({ type: "error", message: error?.message || "Something went wrong" })
        })
    }

    return (
        <Modal
            title="Settings"
            centered
            open={openModal}
            footer={null}
            className="topic-modal"
            maskClosable={false}
            keyboard={false}
            width={800}
            onCancel={closeModal}
        >
            <Spin spinning={isLoading}>
                <Form
                    layout='vertical'
                    ref={formRef}
                    onFinish={onSubmit}
                    disabled={isLoading}
                >
                    <div className="modal-content">
                        <Row gutter={24}>
                            <Col xs={24} sm={8} md={8}>
                                <h3>Personal details</h3>
                            </Col>
                            <Col xs={24} sm={16} md={16}>
                                <Form.Item
                                    label="Fullname"
                                    name="fullname"
                                    rules={[{ required: true, message: 'Please enter your fullname!' }]}
                                >
                                    <Input
                                        size="large"
                                        className="chat-bot-input"
                                        placeholder='Fullname'
                                    />
                                </Form.Item>
                                <Form.Item
                                    label="Email"
                                    name="email"
                                >
                                    <Input
                                        size="large"
                                        className="chat-bot-input"
                                        placeholder='Email'
                                        disabled
                                    />
                                </Form.Item>
                            </Col>
                            {
                                (profile?.provider === "app") &&
                                <>
                                    <Col xs={24} sm={8} md={8}>
                                        <h3>Password</h3>
                                    </Col>
                                    <Col xs={24} sm={16} md={16}>
                                        <Button
                                            size='large'
                                            style={{ borderRadius: 4, marginBottom: 20 }}
                                            onClick={resetEmailLink}
                                        >
                                            Send password reset email
                                        </Button>
                                    </Col>
                                </>
                            }
                            <Col xs={24} sm={8} md={8}>
                                <h3>Affiliations</h3>
                            </Col>
                            <Col xs={24} sm={16} md={16}>
                                <Form.Item
                                    label="Organization"
                                    name="organisation"
                                >
                                    <Input
                                        size="large"
                                        className="chat-bot-input"
                                        placeholder='Organization'
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                    </div>
                    <div className="ant-modal-footer">
                        <Button
                            htmlType="reset"
                            className="border-rounded-2"
                            onClick={closeModal}
                        >
                            Cancel
                        </Button>
                        <Button
                            type="primary"
                            htmlType="submit"
                            className="border-rounded-2"
                        >
                            Save Changes
                        </Button>
                    </div>
                </Form>
            </Spin>
        </Modal>
    )
}

export default UserProfileModal