import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { Line } from "@ant-design/plots";
import { Typography } from "antd";

interface DataItem {
  Date: string;
  scales: number;
}

const ChartPlots = () => {
  const [data, setData] = useState<DataItem[]>([]);

  useEffect(() => {
    asyncFetch();
  }, []);

  const asyncFetch = () => {
    fetch(
      "https://gw.alipayobjects.com/os/bmw-prod/1d565782-dde4-4bb6-8946-ea6a38ccf184.json"
    )
      .then((response) => response.json())
      .then((json) => setData(json))
      .catch((error) => {
        console.log("fetch data failed", error);
      });
  };
  const config = {
    data,
    padding: "auto" as "auto",
    xField: "Date",
    yField: "scales",
    xAxis: {
      type: "timeCat",
      tickCount: 5,
    },
    color: " #9579E2",
  };

  return (
    <div>
      <Typography className="font-14" style={{ marginBottom: "20px" }}>
        Number of Queries
      </Typography>
      <Line {...config} />
    </div>
  );
};

// ReactDOM.render(<ChartPlots />, document.getElementById("root"));
export default ChartPlots;
