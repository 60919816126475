import React, { useEffect, useState } from 'react'
import AuthLayout from '../components/Layout/AuthLayout'
import { Button, Form, Input, Spin } from 'antd'
import { useNavigate } from 'react-router';
import { signupApi } from '../services/ApiActions/authAction';
import Toast from '../services/ToastMessage';
import Auth from '../helper/Authorization'
import { EMAIL_REGEX } from '../constant';
import { Link, useSearchParams } from 'react-router-dom';
import { url } from 'inspector';

type FieldType = {
    fullname: string;
    username?: string;
    password?: string;
    confirm_password?: string
};

const Signup = () => {
    const [isLoading, setLoading] = useState(false);
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const [urlSearchParams] = useSearchParams();

    const onFinish = (data: any) => {
        setLoading(true);
        signupApi({ ...data, provider: "app" })
            .then((res: any) => {
                setLoading(false)
                if (res.status === 201) {
                    Toast({ message: "Email send successfully" });
                    navigate("/email-sent");
                } else {
                    Toast({ message: res?.message || "Something went wrong", type: "error" });
                }
            })
            .catch((error) => {
                setLoading(false)
                Toast({
                    message: error?.message || "Something went wrong",
                    type: "error",
                });
            });
    }

    useEffect(() => {
        if (
            urlSearchParams.has('source') &&
            urlSearchParams.has('planType') &&
            urlSearchParams.get('planType') == '2'
        ) {
            localStorage.setItem(
                'websiteSource',
                JSON.stringify({ source: urlSearchParams.get('source'), planType: urlSearchParams.get('planType') })
            )
        }
    }, [])

    return (
        <AuthLayout>
            <Spin spinning={isLoading}>
                <h1>Create Your Account</h1>
                <Form
                    style={{ paddingTop: "12px" }}
                    layout="vertical"
                    form={form}
                    onFinish={(data: any) => onFinish({ ...data, provider: 'app' })}
                >
                    <Form.Item<FieldType>
                        label="Fullname"
                        style={{ marginBottom: 12 }}
                        name="fullname"
                        rules={[
                            { required: true, message: "Please enter your name" },
                        ]}
                    >
                        <Input
                            size="large"
                            className="chat-bot-input"
                            placeholder="Enter Fullname"
                        />
                    </Form.Item>
                    <Form.Item<FieldType>
                        label="Email"
                        style={{ marginBottom: 12 }}
                        name="username"
                        rules={[
                            { required: true, message: "Please enter your Email" },
                            { pattern: EMAIL_REGEX, message: "Please enter a valid email" }
                        ]}
                    >
                        <Input
                            size="large"
                            className="chat-bot-input"
                            placeholder="Enter Email Address"
                        />
                    </Form.Item>

                    <Form.Item<FieldType>
                        label="Password"
                        name="password"
                        style={{ marginBottom: 12, marginTop: 16 }}
                        rules={[
                            { required: true, message: "Please enter your password" },
                            { min: 8, message: "Password must be 8 character long"},
                        ]}
                    >
                        <Input.Password
                            size="large"
                            className="chat-bot-input"
                            placeholder="Enter Your Passwod"
                        />
                    </Form.Item>

                    <Form.Item<FieldType>
                        label="Confirm Password"
                        name="confirm_password"
                        dependencies={['password']}
                        style={{ marginBottom: 12, marginTop: 16 }}
                        rules={[
                            { required: true, message: "Please confirm your password" },
                            ({ getFieldValue }) => ({
                                validator(_, value) {
                                    if (!value || getFieldValue('password') === value) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject(new Error('The new password that you entered do not match!'));
                                },
                            }),
                        ]}
                    >
                        <Input.Password
                            size="large"
                            className="chat-bot-input"
                            placeholder="Enter Your Passwod"
                        />
                    </Form.Item>
                    <Button
                        size="large"
                        block
                        style={{ borderRadius: "2px", marginTop: 12 }}
                        type="primary"
                        htmlType="submit"
                    >
                        Create Account
                    </Button>
                </Form>
                <div className='auth-return-link'>
                    Have an account? <Link to="/login">Login</Link>
                </div>
            </Spin>
        </AuthLayout>
    )
}

export default Signup