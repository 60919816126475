import React from 'react'

const FailedIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="49" height="48" viewBox="0 0 49 48" fill="none">
            <path d="M24.5 3C12.9031 3 3.5 12.4031 3.5 24C3.5 35.5969 12.9031 45 24.5 45C36.0969 45 45.5 35.5969 45.5 24C45.5 12.4031 36.0969 3 24.5 3ZM32.2531 31.9781L29.1594 31.9641L24.5 26.4094L19.8453 31.9594L16.7469 31.9734C16.5406 31.9734 16.3719 31.8094 16.3719 31.5984C16.3719 31.5094 16.4047 31.425 16.4609 31.3547L22.5594 24.0891L16.4609 16.8281C16.4043 16.7594 16.3729 16.6734 16.3719 16.5844C16.3719 16.3781 16.5406 16.2094 16.7469 16.2094L19.8453 16.2234L24.5 21.7781L29.1547 16.2281L32.2484 16.2141C32.4547 16.2141 32.6234 16.3781 32.6234 16.5891C32.6234 16.6781 32.5906 16.7625 32.5344 16.8328L26.4453 24.0938L32.5391 31.3594C32.5953 31.4297 32.6281 31.5141 32.6281 31.6031C32.6281 31.8094 32.4594 31.9781 32.2531 31.9781Z" fill="#CF1322" />
        </svg>
    )
}

export default FailedIcon