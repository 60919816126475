import { Input, Layout, theme } from "antd";
import Sidebar from "./Sidebar";
import { Outlet, useNavigate } from "react-router-dom";
import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { AppDispatch, RootState } from "../../reducers/reduxStore";
import {
    handleSubTopicModal,
    hanleAddTopicModal,
    setApiLoading,
} from "../../reducers/ModalSlice";
import FormModal from "../Widget/FormModal";
import { createTopic, getTopicList } from "../../services/ApiActions/topicAct";
import Toast from "../../services/ToastMessage";
import { createSubtopic } from "../../services/ApiActions/subTopicAct";
import type { InputRef } from "antd";
import { current } from "@reduxjs/toolkit";

type SubtopicType = {
    name: string;
    id: string;
    updated_at: string;
    created_at: string;
};

export type MenuListType = {
    name: string;
    id: string;
    updated_at: string;
    created_at: string;
    subtopics: SubtopicType[];
};

const CommonLayout = () => {
    const {
        token: { colorBgContainer },
    } = theme.useToken();

    const navigate = useNavigate();

    const [newTopic, setNewTopic] = useState("");
    const [newSubTopic, setNewSubTopic] = useState("");
    const [menuList, setMenuList] = useState<MenuListType[] | []>([]);
    const [formError, setFormError] = useState({
        topicError: "",
        subTopicError: "",
    });

    const { openTopicModal, openSubTopicModal, reFetchingMenu, isApiLoading } =
        useSelector((state: RootState) => state.modal);
    const dispatch = useDispatch<AppDispatch>();

    const topicRef = useRef<InputRef>(null);
    const subTopicRef = useRef<InputRef>(null);

    const handleFormError = (type: string, message: string) => {
        setFormError((prev) => {
            return {
                ...prev,
                [type]: message,
            };
        });
    };

    /*
     * To close Topic modal
     */
    const closeTopicModal = () => {
        setNewTopic("");
        dispatch(hanleAddTopicModal(false));
    };

    /*
     * To close sub topic modal
     */
    const closeSubTopicModal = () => {
        setNewSubTopic("");
        dispatch(handleSubTopicModal(""));
    };

    /*
     * Handling onchange topic input
     */
    const hanldeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        setNewTopic(value);
    };

    /*
     * Handling onchange sub topic input
     */
    const hanldeChangeSubTopic = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        setNewSubTopic(value);
    };

    /*
     * To Create a new topic
     */
    const onCreateTopic = () => {
        if (newTopic.trim() === "") {
            handleFormError("topicError", "Please enter topic");
            return;
        }

        handleFormError("topicError", "");
        dispatch(setApiLoading(true));
        createTopic({ name: newTopic.trim() })
            .then((res: any) => {
                dispatch(setApiLoading(false));
                if (res.status === "success") {
                    setMenuList((pre) => {
                        return [...pre, { ...res.data, subtopics: [] }];
                    });
                    closeTopicModal();
                    Toast({ message: "Topic created successfully" });
                    navigate(`/chat/${res.data.id}`);
                } else {
                    Toast({
                        type: "error",
                        message: res?.message || "Something went wrong",
                    });
                }
            })
            .catch((error) => {
                dispatch(setApiLoading(false));
                Toast({
                    type: "error",
                    message: error?.message || "Something went wrong",
                });
            });
    };

    const onCreateSubTopic = () => {
        if (newSubTopic.trim() === "") {
            handleFormError("subTopicError", "Please enter sub topic");
            return;
        }

        handleFormError("subTopicError", "");
        dispatch(setApiLoading(true));
        createSubtopic(openSubTopicModal, { name: newSubTopic.trim() })
            .then((res: any) => {
                dispatch(setApiLoading(false));
                if (res.status === "success") {
                    let _menuList: MenuListType[] = menuList.map((item) => {
                        if (item.id === openSubTopicModal) {
                            item.subtopics = [...item.subtopics, res.data];
                            return item;
                        }
                        return item;
                    });
                    setMenuList([..._menuList]);
                    Toast({ message: "Sub topic created successfully" });
                    navigate(`/chat/${openSubTopicModal}/${res.data.id}`);
                    closeSubTopicModal();
                } else {
                    Toast({
                        type: "error",
                        message: res?.message || "Something went wrong",
                    });
                }
            })
            .catch((error) => {
                dispatch(setApiLoading(false));
                Toast({
                    type: "error",
                    message: error?.message || "Something went wrong",
                });
            });
    };

    /*
     * Getting list of main topics
     */
    useEffect(() => {
        getTopicList().then((res: any) => {
            setMenuList(res.data.data);
        });
    }, [reFetchingMenu]);

    useEffect(() => {
        setFormError({ topicError: "", subTopicError: "" })
        if (openTopicModal) {
            setTimeout(() => {
                topicRef.current?.focus();
            }, 300)
        }
    }, [openTopicModal]);

    useEffect(() => {
        setFormError({ topicError: "", subTopicError: "" })
        if (openSubTopicModal !== "") {
            setTimeout(() => {
                subTopicRef.current?.focus();
            }, 300)
        }
    }, [openSubTopicModal]);

    return (
        <>
            <Layout>
                <Sidebar menuList={menuList} />
                <Layout
                    className="site-layout"
                //  style={{ background: colorBgContainer }}
                >
                    <Outlet />
                </Layout>
            </Layout>

            {/* New Topic modal started */}
            <FormModal
                title="Create new topic"
                openModal={openTopicModal}
                onCancelClick={closeTopicModal}
                onOkClick={onCreateTopic}
            >
                <Input
                    ref={topicRef}
                    type="text"
                    size="large"
                    className="chat-bot-input"
                    placeholder="Enter new topic name"
                    onPressEnter={onCreateTopic}
                    onChange={hanldeChange}
                    value={newTopic}
                />
                {formError.topicError && (
                    <span className="form-error">{formError.topicError}</span>
                )}
            </FormModal>

            {/* New Sub topic modal started */}
            <FormModal
                title="Add sub topic name"
                openModal={openSubTopicModal !== ""}
                onCancelClick={closeSubTopicModal}
                onOkClick={onCreateSubTopic}
            >
                <Input
                    ref={subTopicRef}
                    type="text"
                    size="large"
                    className="chat-bot-input"
                    placeholder="Enter sub topic name"
                    onPressEnter={onCreateSubTopic}
                    onChange={hanldeChangeSubTopic}
                    value={newSubTopic}
                />
                {formError.subTopicError && (
                    <span className="form-error">{formError.subTopicError}</span>
                )}
            </FormModal>
        </>
    );
};

export default CommonLayout;
