import { PayloadAction, createSlice } from "@reduxjs/toolkit";

type ProfileTypes = {
    username?: string,
    name?: string,
    profile_pic?: string,
    organisation?: string,
    subscriptionType?: string,
    provider?: string,
    topic_count: number,
    subtopic_count: number
}

type InitialStateType = {
    profile: ProfileTypes,
    topicCount: number,
    subTopicCount: number
}

const initialState: InitialStateType = {
    profile: {
        topic_count: 0,
        subtopic_count: 0
    },
    topicCount: 0,
    subTopicCount: 0
}

export const UserSlice = createSlice({
    name: 'userReducer',
    initialState: initialState,
    reducers: {
        setProfileDetails: (state, action: PayloadAction<ProfileTypes>) => {
            state.profile = action.payload;
        },
        resetProfileDetails: (state) => {
            state.profile = {
                topic_count: 0,
                subtopic_count: 0
            };
        },
        setCounts: (state, action: PayloadAction<{ topicCount: number, subTopicCount: number }>) => {
            state.topicCount = action.payload.topicCount;
            state.subTopicCount = action.payload.subTopicCount;
        },
        updateProfileDetails: (state, action: PayloadAction<ProfileTypes>) => {
            state.profile.name = action.payload.name;
            state.profile.organisation = action.payload.organisation;
        }
    }
})

export const {
    setProfileDetails,
    resetProfileDetails,
    setCounts,
    updateProfileDetails
} = UserSlice.actions

export default UserSlice.reducer;