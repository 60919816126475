import React, { forwardRef } from "react";
import { FolderAddOutlined, PlusOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../reducers/reduxStore";
import {
    hanleAddTopicModal,
    handleSubTopicModal,
    handleViewPlanModal,
} from "../reducers/ModalSlice";
import { handelFileModal } from "../reducers/ModalSlice";
import { Button, Col } from "antd";
import { useParams } from "react-router-dom";
import { InfoColor } from "../components/Colors";

type StepsType = {
    fileList?: {
        id?: string;
        name?: string;
        path?: string;
        updated_at?: string;
    }[];
    isChatEnable?: boolean;
    topicStatus?: boolean;
    subTopicStatus?: boolean;
    topicStatusfor?: boolean;
    isSubtopiDisabled?: boolean;
    isFileDisabled?: boolean;
    hideCreateTopicBtn?: boolean,
};

const ProceedSteps = ({
    fileList = [],
    isChatEnable,
    topicStatus,
    subTopicStatus,
    isSubtopiDisabled = true,
    isFileDisabled = true,
    hideCreateTopicBtn = false,
}: StepsType) => {
    const dispatch = useDispatch<AppDispatch>();
    const { topic, sub_topic } = useParams();
    const { profile, topicCount, subTopicCount } = useSelector((state: RootState) => state.userProfile)
    return (
        <div className="left-panel">
            <p style={{ fontSize: 26, fontWeight: 500 }}>
                Pro Tips for Streamlined PDF Chats
            </p>
            <p>
                We're here to help you effortlessly manage your files as you engage with
                your PDFs!
            </p>

            {/* Topic create button */}
            {
                (!hideCreateTopicBtn) &&
                <p style={{ marginTop: 40, marginBottom: 5 }}>
                    Begin by establishing a new topic
                </p>
            }

            {
                (!hideCreateTopicBtn) &&
                (
                    (topic !== undefined) ? (
                        <div className="mb-30">
                            <button className="add-topic-disabled">
                                <img
                                    src="/images/Plus.png"
                                    alt=""
                                    style={{ margin: "0px 7px -2px auto" }}
                                />
                                Create a new topic
                            </button>

                            <img
                                src="/images/check-circle.png"
                                alt="check"
                                style={{ margin: "auto auto -6px 10px" }}
                            />
                        </div>
                    ) : (
                        <div className="chat-message">
                            <InfoColor>
                                <Button
                                    style={{ background: "#9579E2" }}
                                    type="primary"
                                    onClick={() => {
                                        // if(!profile.hasOwnProperty('subscriptionType') || profile.subscriptionType==='FREE'){
                                        if (topicCount >= profile.topic_count) {
                                            dispatch(handleViewPlanModal(true))
                                        } else {
                                            dispatch(hanleAddTopicModal(true))
                                        }
                                    }}
                                >
                                    <PlusOutlined color="white" /> Create a new topic
                                </Button>
                            </InfoColor>
                        </div>
                    )
                )
            }

            {/* Sub topic create button */}
            <div className={`subTopic-btn ${hideCreateTopicBtn ? 'mt-25' : ''}`}>
                <label>Next, include a sub-topic for uploading your PDFs</label>
                <div>
                    <button
                        disabled={isSubtopiDisabled}
                        className="add-subtopic-btn sub-topic"
                        onClick={() => dispatch(handleSubTopicModal(topic as string))}
                    >
                        <FolderAddOutlined className="fs-18" />
                        Add sub topic
                    </button>
                    {(sub_topic !== undefined) && (
                        <img
                            src="/images/check-circle.png"
                            alt="check"
                            style={{ margin: "auto auto 20px 10px" }}
                        />
                    )}
                </div>
            </div>

            {/* ======================================= */}

            {/* {fileList && fileList.length > 0 ? (
                <div className="">
                    {/* <img src="/images/logo-sm.png" alt="Chat bot" /> 
                    <div style={{ marginTop: 15 }}>
                        You have successfully uploaded file, now you can start chat...
                    </div>
                </div>
            ) : ( */}
            <div
                className="chat-message-upload"
                style={{
                    marginTop: 40,
                    display: "flex",
                    flexDirection: "column",
                }}
            >
                <p>Finally, proceed to upload your PDFs</p>
                <button
                    //   disabled={!subTopicStatus}
                    disabled={!(sub_topic !== undefined) || isFileDisabled}
                    className="add-subtopic-btn"
                    onClick={() => dispatch(handelFileModal(true))}
                >
                    <FolderAddOutlined className="fs-18" />
                    Upload files
                </button>
            </div>
            {/* )} */}
        </div >
    );
};

export default ProceedSteps;
