import { Modal } from "antd";
import { Viewer, Worker } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import { searchPlugin, OnHighlightKeyword } from "@react-pdf-viewer/search";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import "@react-pdf-viewer/search/lib/styles/index.css";
import { useEffect, useState } from "react";
// import { pageNavigationPlugin } from '@react-pdf-viewer/page-navigation';
import JumpToPagePlugin from "./JumpToPlugin";

type PDFViewerType = {
    file?: string;
    fileContent: string;
    filePageNum?: any;
    isFullUrl?: boolean
};

const PDF = ({ file, fileContent, filePageNum, isFullUrl = false }: PDFViewerType) => {
    const [isDocumentLoaded, setDocumentLoaded] = useState(false);
    const [finalArr, setFinalArr] = useState<string[]>([]);
    const [currentPage, setCurrentPage] = useState(filePageNum);
    const jumpToPagePluginInstance = JumpToPagePlugin();
    const { jumpToPage } = jumpToPagePluginInstance;

    // Initialize defaultLayoutPlugin and searchPlugin instances
    const defaultLayoutPluginInstance = defaultLayoutPlugin();

    const searchPluginInstance = searchPlugin({
        keyword: [...finalArr],
    });

    const { setTargetPages } = searchPluginInstance;
    setTargetPages((targetPage: any) => targetPage.pageIndex === filePageNum);
    jumpToPage(filePageNum);

    const handlePDFLoad = () => {
        setDocumentLoaded(true);
        setCurrentPage(filePageNum);
    };

    useEffect(() => {
        const delimiters = /[\n]+/;
        const resultArray = fileContent.split(delimiters);

        const newArr = [];
        for (var i = 0; i < resultArray.length; i++) {
            let x = resultArray[i].trim();
            let y = x.replace("  ", " ");
            newArr.push(y);
        }

        setFinalArr(newArr);
    }, [fileContent]);

    return (
        <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.16.105/build/pdf.worker.js">
            <div
                style={{
                    height: "100vh",
                    width: "100%",
                    margin: "auto",
                }}
            >
                <Viewer
                    fileUrl={isFullUrl ? file as string : `https://dev-gist-chatbot-app-assets.s3.ap-south-1.amazonaws.com/public/${file}`}
                    plugins={[
                        defaultLayoutPluginInstance,
                        searchPluginInstance,
                        jumpToPagePluginInstance,
                    ]}
                    onDocumentLoad={handlePDFLoad}
                />
            </div>
        </Worker>
    );
};

export default PDF;
