import { Avatar, Button, Divider, Popover, Progress } from "antd";
import {
    EllipsisOutlined,
    SettingOutlined,
    LogoutOutlined,
    UserOutlined,
} from "@ant-design/icons";
import Auth from "../../../helper/Authorization";
import { Navigate, redirect, useNavigate } from "react-router-dom";
import { useState } from "react";
import UserProfileModal from "../../Widget/UserProfileModal";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../reducers/reduxStore";
import { resetProfileDetails, setProfileDetails } from "../../../reducers/UserSlice";

type SiderFooterTypes = {
    name: string;
    subscriptionType: string;
};

type ContentTypes = {
    hideVisibility: () => void;
};

const Content = ({ hideVisibility }: ContentTypes) => {
    const [isOpentProfileModal, setProfileModal] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch<AppDispatch>();

    const logOut = () => {
        dispatch(resetProfileDetails())
        Auth.logout();
        navigate("/login");
    };

    return (
        <>
            <div style={{ display: "flex", flexDirection: "column" }}>
                {/* <div style={{ width: "100%" }}>
                <Progress
                    percent={30}
                    size="small"
                    style={{ margin: 0 }}
                    showInfo={false}
                />
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <div>user</div>
                    <div>25/50</div>
                </div>
            </div>
            <div style={{ width: "100%", marginBottom: "20px" }}>
                <Progress
                    percent={30}
                    size="small"
                    style={{ margin: 0 }}
                    showInfo={false}
                />
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <span> File Size</span> <span> 18/50GB</span>
                </div>
            </div> */}
                <Button
                    type="text"
                    style={{
                        outline: "none",
                        border: "none",
                        textAlign: "left",
                        boxShadow: "none",
                        padding: "0",
                    }}
                    icon={<SettingOutlined />}
                    onClick={() => {
                        setProfileModal(true);
                        hideVisibility();
                    }}
                >
                    Settings
                </Button>
                <Divider style={{ margin: "5px", padding: "0 0" }} />
                <Button
                    onClick={logOut}
                    style={{
                        outline: "none",
                        border: "none",
                        textAlign: "left",
                        boxShadow: "none",
                        padding: "0",
                    }}
                    icon={<LogoutOutlined />}
                >
                    Logout{" "}
                </Button>
            </div>
            <UserProfileModal
                openModal={isOpentProfileModal}
                closeModal={() => setProfileModal(false)}
            />
        </>
    );
};

const SiderFooter = ({ name = "", subscriptionType }: SiderFooterTypes) => {
    const navigate = useNavigate();
    const [popoverVisibility, setPopoverVisibility] = useState(false);

    return (
        <div className="sidebar-footer">
            {/* {
                subscriptionType === "FREE" &&
                <Button
                    style={{ boxShadow: "1px 1px 9px 1.5px #aaa" }}
                    type="primary"
                    size="large"
                    block
                    className="border-rounded-0 mb-16 d-none d-md-block"
                    onClick={() => navigate("/plans")}
                >
                    Upgrade Pro
                    <img
                        src="/images/icons/checked.svg"
                        className="icon-checked"
                        alt="Upgrade"
                    />
                </Button>
            } */}
            <div className="flex-between-center">
                <div className="user-avatar">
                    {/* <UserOutlined style={{ fontSize: "180%" }} /> */}
                    <Avatar
                        size="large"
                        shape="square"
                        icon={<UserOutlined />}
                        gap={2}
                    />
                    <p
                        className="ml-8 user-name user-profile-name "
                    >
                        {name}
                    </p>
                </div>
                <Popover
                    placement="topRight"
                    title=" "
                    content={
                        <Content hideVisibility={() => setPopoverVisibility(false)} />
                    }
                    open={popoverVisibility}
                    onOpenChange={(open) => setPopoverVisibility(open)}
                    trigger="click"
                >
                    <Button type="text" icon={<EllipsisOutlined />} />
                </Popover>
            </div>
        </div>
    );
};

export default SiderFooter;
