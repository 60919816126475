import { Modal } from "antd";
import { Viewer, Worker } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import { searchPlugin, OnHighlightKeyword } from "@react-pdf-viewer/search";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import "@react-pdf-viewer/search/lib/styles/index.css";
import { useEffect, useState } from "react";
// import { pageNavigationPlugin } from '@react-pdf-viewer/page-navigation';
import JumpToPagePlugin from "./JumpToPlugin";

type PDFViewerType = {
  openModal: boolean;
  closeModal: () => void;
  file: string;
  fileContent: string;
  filePageNum: any;
};

const PDFViewer = ({
  openModal,
  closeModal,
  file,
  fileContent,
  filePageNum,
}: PDFViewerType) => {
  const [isDocumentLoaded, setDocumentLoaded] = useState(false);
  const [finalArr, setFinalArr] = useState<string[]>([]);
  const [currentPage, setCurrentPage] = useState(filePageNum);
  const jumpToPagePluginInstance = JumpToPagePlugin();
  const { jumpToPage } = jumpToPagePluginInstance;

  // Initialize defaultLayoutPlugin and searchPlugin instances
  const defaultLayoutPluginInstance = defaultLayoutPlugin();

  const searchPluginInstance = searchPlugin({
    keyword: [...finalArr],
    // onHighlightKeyword: (props: OnHighlightKeyword) => {
    //   props.highlightEle.style.outline = '1px dashed blue';
    //   props.highlightEle.style.backgroundColor = 'transparent'; // Set background to transparent
    // },
  });

  // //for finding the searched string within any specific page
  const { setTargetPages } = searchPluginInstance;
  setTargetPages((targetPage) => targetPage.pageIndex === filePageNum);
  jumpToPage(filePageNum);

  const handlePDFLoad = () => {
    setDocumentLoaded(true);
    setCurrentPage(filePageNum);
    // console.log(fileContent);
    // console.log(filePageNum);


    // Add your code here to execute when the PDF is loaded
  };

  useEffect(() => {
    const delimiters = /[\n]+/;
    const resultArray = fileContent.split(delimiters);

    // console.log("Type of result array is->" + typeof resultArray);

    const newArr = [];
    for (var i = 0; i < resultArray.length; i++) {
      let x = resultArray[i].trim();
      let y = x.replace("  ", " ");
      newArr.push(y);
    }

    setFinalArr(newArr);
  }, [fileContent]);

  return (
    <Modal
      title="PDF Viewer"
      centered
      open={openModal}
      onCancel={closeModal}
      width={890}
      okButtonProps={{ className: "d-none" }}
      cancelText="Close"
    >
      <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.16.105/build/pdf.worker.js">
        <div
          style={{
            height: "79vh",
            width: "100%",
            margin: "auto",
          }}
        >
          <Viewer
            fileUrl={`https://dev-gist-chatbot-app-assets.s3.ap-south-1.amazonaws.com/public/${file}`}
            plugins={[
              defaultLayoutPluginInstance,
              searchPluginInstance,
              jumpToPagePluginInstance,
            ]}
            onDocumentLoad={handlePDFLoad}
          />
        </div>
      </Worker>
    </Modal>
  );
};

export default PDFViewer;
