import React from "react";
import { Button, Typography, Card, DatePicker, Space } from "antd";
import type { DatePickerProps } from "antd";
import MyTable from "../components/analysis/MyTable";
import ChartBar from "../components/analysis/ChartBar";
import ChartPlots from "../components/analysis/ChartPlote";
import SearchTable from "../components/analysis/SearchTable";

const onChange: DatePickerProps["onChange"] = (date, dateString) => {
  console.log(date, dateString);
};
const Analisys = () => {
  return (
    <div className="analisys-box">
      <div
        className="analisys-box-top"
        style={{ paddingTop: 22, paddingBottom: 16 }}
      >
        Overview
      </div>
      <Button
        className=" font-14 , flex"
        style={{ paddingBottom: 20, border: "0" }}
      >
        <img src="/images/icons/Initial.svg" className="icon-checked" />
        <span style={{ color: "rgba(0, 0, 0, 0.45)" }}>Setting/</span>
        <span>Overview</span>
      </Button>

      <div className="flex" style={{ gap: "1rem" }}>
        <div className="border-card" style={{ width: 166 }}>
          <Typography className="font-16">Member</Typography>
          <Typography className="font-30">20</Typography>
        </div>
        <div className="border-card" style={{ width: 166 }}>
          <Typography className="font-16">Topics</Typography>
          <Typography className="font-30">5</Typography>
        </div>
        <div className="border-card" style={{ width: 166 }}>
          <Typography className="font-16">Pdfs</Typography>
          <Typography className="font-30">
            13 <span className="font-20">GB</span>
          </Typography>
        </div>
        <div className="border-card" style={{ width: 166 }}>
          <Typography className="font-16">Average Time</Typography>
          <Typography className="font-30">
            1.01 <span className="font-20">sec</span>{" "}
          </Typography>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: "30px",
        }}
      >
        <Typography className="font-24">Analytics</Typography>
        <Space>
          {" "}
          <DatePicker style={{ borderRadius: 2 }} onChange={onChange} />
        </Space>
      </div>
      <div
        style={{
          width: "100%",
          marginTop: "30px",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div style={{ width: "49%", marginLeft: "10px" }}>
          <ChartPlots />
        </div>
        <div style={{ width: "49%" }}>
          <ChartBar />
        </div>
      </div>
      <div>
        <SearchTable />
      </div>
      <div>
        <MyTable />
      </div>
    </div>
  );
};

export default Analisys;
