import api from "../api";
import { addQuery } from "../api.helper";
import { subTopicApi } from "../apiVariables";

export const getSubtopicList = (topic: string) => {
  let { getSubTopicList } = subTopicApi;
  getSubTopicList.topic = topic;
  return new Promise((resolve, reject) => {
    api({ ...subTopicApi.getSubTopicList })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const createSubtopic = (topic: string, body: any) => {
  let { createSubTopic } = subTopicApi;
  createSubTopic.topic = topic;
  return new Promise((resolve, reject) => {
    api({ ...subTopicApi.createSubTopic, body })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getSubTopicDetails = (topic: string, sub_topic: string) => {
  let { getSubTopic } = subTopicApi;
  getSubTopic.topicId = topic;
  getSubTopic.subTopicId = sub_topic;
  return new Promise((resolve, reject) => {
    api({ ...subTopicApi.getSubTopic })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const sendQuery = async (body: any) => {
  return await new Promise((resolve, reject) => {
    api({ ...subTopicApi.sendQuery, body })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const deleteSubtopic = (topic: string, subTopic: string) => {
  let { deleteSubtopic } = subTopicApi;
  deleteSubtopic.topic = topic;
  deleteSubtopic.subTopic = subTopic;
  return new Promise((resolve, reject) => {
    api({ ...subTopicApi.deleteSubtopic })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getQueryHistory = (query: any) => {
  addQuery(query, subTopicApi.getMessageHistory);
  return new Promise((resolve, reject) => {
    api({ ...subTopicApi.getMessageHistory })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
