import BlankMessage from "../components/Homepage/BlankMessage";
import { FolderAddOutlined } from "@ant-design/icons";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../reducers/reduxStore";
import { handleSubTopicModal } from "../reducers/ModalSlice";
import ChatSection from "../components/Layout/ChatSection";
import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getTopicDetails } from "../services/ApiActions/topicAct";
import Toast from "../services/ToastMessage";
import SplitPane from "split-pane-react/esm/SplitPane";
import ProceedSteps from "./ProceedSteps";
import SplitePanel from "../components/SplitePanel";
import LogoSm from "../icons/LogoSm";

export type TopicDataType = {
    id: string;
    name: string;
    updated_at: string;
};

const TopicChat = () => {
    const dispatch = useDispatch<AppDispatch>();
    const { topic } = useParams();
    const navigate = useNavigate();
    const [topicData, setTopicData] = useState<TopicDataType>();
    const [topicStatus, setTopicStatus] = useState(false);
    const [sizes, setSizes] = useState<(number | string)[]>(["50%", "50%"]);

    const messageRef = useRef<HTMLDivElement | null>(null);
    useEffect(() => {
        messageRef?.current?.scrollIntoView({ behavior: "smooth" });
    }, [topic]);

    useEffect(() => {
        getTopicDetails(topic as string)
            .then((res: any) => {
                if (res.data.status === "success") {
                    setTopicData({ ...res.data });
                    setTopicStatus(true);
                } else if (res.data.status === 404) {
                    Toast({ type: "error", message: "Topic not found" })
                    navigate('/', { replace: true })
                }
            })
            .catch((error) => {
                Toast({
                    type: "error",
                    message: error?.message || "Something went wrong",
                });
            });
    }, [topic]);


    const leftPanelStyle = {
        height: "100%",
        borderLeft: "1px solid #ddd",
    };

    const rightPanelStyle = {
        border: "1px solid #ddd",
        height: "100%",
    };

    return (
        <>
            <SplitePanel>
                <SplitePanel.LeftPane>
                    <ProceedSteps isSubtopiDisabled={false} />
                </SplitePanel.LeftPane>
                <SplitePanel.RightPane>
                    <ChatSection
                        ref={messageRef}
                        headerTitle={topicData?.name}
                        topicId={topic}
                    >
                        <div className="chat-message message-bot">
                            {/* <img src="/images/logo-sm.png" alt="Chat bot" /> */}
                            <LogoSm />
                            <p className="query-response">
                                You have successfully created new topic <b>{topicData?.name}</b>
                                . Add a sub topic to continue...
                            </p>
                        </div>
                    </ChatSection>
                </SplitePanel.RightPane>
            </SplitePanel>
        </>
    );
};

export default TopicChat;
